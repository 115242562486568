import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfigServiceService } from './config-service.service';

@Injectable({
  providedIn: 'root'
})
export class HeatmapscriptService {
  private doc: Document;
  private renderer: Renderer2;
  clarityId: string;
  clarityEnabled: any;

  constructor(
    private configService: ConfigServiceService,
    private http: HttpClient,
    @Inject(DOCUMENT) private injectedDocument: any,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() private router: Router,
    private rendererFactory: RendererFactory2
  ) {
    this.doc = injectedDocument as Document;
    this.renderer = rendererFactory.createRenderer(null, null);
  }
  fetchAndAddScript(): void {
    this.getConfigData();
    
 
    if (this.clarityEnabled === 1) {
      const scriptContent = this.generateClarityScript(this.clarityId);
      this.addScriptToHead(scriptContent);
    }
  }

  getConfigData() {
    this.configService.getConfigData()
      .subscribe(
        data => {
          this.clarityId = data['clarityId'];
          this.clarityEnabled = data['clarityEnabled'];
          if (this.clarityEnabled === 1) {
            this.fetchAndAddScript();
          }
        },
        error => {
          console.error('Error fetching config data', error);
        }
      );
  }

  generateClarityScript(clarityId: string): string {
    return `
      (function(c, l, a, r, i, t, y){
          c[a] = c[a] || function(){(c[a].q = c[a].q || []).push(arguments)};
          t = l.createElement(r);
          t.async = 1;
          t.src = "https://www.clarity.ms/tag/" + i;
          y = l.getElementsByTagName(r)[0];
          y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", "${clarityId}");
    `;
  }


  private addScriptToHead(scriptContent: string): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const script = this.renderer.createElement('script');
    this.renderer.setAttribute(script, 'type', 'text/javascript');
    this.renderer.setProperty(script, 'innerHTML', scriptContent);
    this.renderer.appendChild(this.doc.head, script);
  }
}