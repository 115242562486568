<div class="pointer" *ngIf="productLayoutType==0" class="product-item product-elevat ext-center">
  <div fxLayout="row" fxLayoutAlign="space-between center" style="position: sticky;z-index: 10;">
    <calsoft-controls [data]="data" [variant1Name]="variant1Name" [variant2Name]="variant2Name"
      [variant1Value]="variant1Value" [variant2Value]="variant2Value" [product]="configProductSelected"
      [configproduct]="config " style="position: absolute; top: 0; left: 0" [type]="'wishonly'">
    </calsoft-controls>

    <div class="sale-label" style="z-index: 1000;" *ngIf="discountPercentage > 0 && discountPercentage < 100">

      {{ discountPercentage | dynamicRound}}% Off
    </div>
  </div>

  <div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <calsoft-image-lazy-loading *ngIf="showAnimation"
        [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }" class="padding-10"
        (click)="productShow(product)" [width]="width" [imageType]="imageType" [heightMobile]="heightMobile"
        [widthMobile]="widthMobile" [height]="height" [type]="type" class="pointer" [name]="name" [image]="image">
      </calsoft-image-lazy-loading>


      <calsoft-image-lazy-main-mouse-over-main *ngIf="!showAnimation" [type]="type"
        [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }" class="padding-10"
        (click)="productShow(product)" [qty]="qty" [product]="product" [width]="width" [imageType]="imageType"
        [heightMobile]="heightMobile" [widthMobile]="widthMobile" [height]="height" [type]="type" class="pointer"
        [name]="name" [list]="list"></calsoft-image-lazy-main-mouse-over-main>

    </div>
    <span *ngIf="product['type']!='Course'">
      <span *ngIf="this.product.type!='Booking'">
        <div *ngIf="outofstockEnabled==1">
          <div (click)="productShow(product)" class="outof centered1 title center" fxLayout="row"
            fxLayoutAlign="center center" *ngIf="(qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0)">
            <small fxLayoutAlign="center center" class="colorRed">OUT OF STOCK</small>
          </div>
        </div>
      </span>
    </span>



  </div>
  <div class="productPrice padding-rl10" (click)="productShow(product)" fxLayout="row" fxLayoutAlign="start center">

    <div *ngIf="!displayNameFully" [style.height]="data.categoryPageProductNameHeight"
      [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}" fxFlex>
      <div fxLayout="row">
        <div class="truncate-text" [fxFlex]="prescriptionRequired?96:100">

          <calsoft-translator [translateValue]="name" [data]="data" [type]="'products'"></calsoft-translator>

        </div>
        <img fxFlex height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
          alt="prescriptionRequired">
      </div>

    </div>

    <div fxFlex *ngIf="displayNameFully" [style.height]="data.categoryPageProductNameHeight"
      [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}">
      <div fxLayout="row">
        <calsoft-translator [fxFlex]="prescriptionRequired?96:100" [translateValue]="name" [data]="data"
          [type]="'productsNew'"></calsoft-translator>
        <img height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
          alt="prescriptionRequired">
      </div>
    </div>



  </div>


  <!-- <div [ngClass]="{
      padinf:
        configurableProductObject['type'] == 'Configurable' &&
        configurableProductObject['configurableProductDropdown'] != null
    }" class="padding-rl10" *ngIf="averageRating >= 1" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <calsoft-star-rate [averageRating]="averageRating" [calculateAvgRating]="calculateAvgRating">
    </calsoft-star-rate>
  </div> -->
  <div [ngClass]="{ overlayProduct: qty <= 0 && manageStock == 1 }" *ngIf="averageRating == 0"
    style="padding-bottom: 20px"></div>

  <div *ngIf="showConfigurableDropdown == 1">

    <ng-container *ngIf="!showNewConfig; else newconfig">
      <div class="padding-rl10 A" fxLayout="row" *ngFor="let item of 
      configurationDropDownList | keyvalue">
        <mat-form-field style="
            font-size: 10px;
            height: 54px;
            padding: 0px;
            margin: 0px;
            padding-bottom: 0px;
          " appearance="outline" fxFlex="100">
          <mat-label style="font-size: 12px">
            {{ item.key.replace('_', ' ') }}
          </mat-label>
          <mat-select style="padding-bottom: 0.34375em; padding-top: 5px"
            (selectionChange)="getConfigProductId($event.value)" [disableOptionCentering]="true"
            [formControl]="dropDownValue">
            <mat-option style="font-size: 14px" [value]="object" *ngFor="let object of item.value">
              {{ object | splitbyHash }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-template #newconfig>

      <div class="padding-rl10 A" fxLayout="row">
        <mat-form-field style="
            font-size: 10px;
            height: 54px;
            padding: 0px;
            margin: 0px;
            padding-bottom: 0px;
          " appearance="outline" fxFlex="100">
          <mat-label style="font-size: 12px">
            {{configurationDropDownList[0].key}}
          </mat-label>
          <mat-select [(ngModel)]="configurationDropDownList[firstIndex].value" [disableOptionCentering]="true">
            <mat-option (onSelectionChange)="getConfigProductIdNew($event,i,item,'firstIndex')" [value]="item.value"
              *ngFor="let item of configurationDropDownList;let i=index;">
              {{item.value}}
            </mat-option>
          </mat-select>

        </mat-form-field>
      </div>

      <div class="padding-rl10 A" fxLayout="row">
        <mat-form-field style="
        font-size: 10px;
        height: 54px;
        padding: 0px;
        margin: 0px;
        padding-bottom: 0px;
      " appearance="outline" fxFlex="100">
          <mat-label style="font-size: 12px">
            {{configurationDropDownList[0]
            ['recursuive'][0].key}}

          </mat-label>
          <mat-select [(ngModel)]="configurationDropDownList[firstIndex]
              .recursuive[secondIndex].value" [disableOptionCentering]="true">
            <mat-option (onSelectionChange)="getConfigProductIdNew($event,j,item1,'secondIndex')" [value]="item1.value"
              *ngFor="let item1 of configurationDropDownList[firstIndex]
       ['recursuive'];let j=index;">
              {{item1.value}}
            </mat-option>
          </mat-select>


        </mat-form-field>
      </div>


    </ng-template>
  </div>

  <div *ngIf="this.data.disablePrice==0">
    <div [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }">
      <div *ngIf="inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
        fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

        <span *ngIf="displayMrpPrice">
          <span *ngIf="specialPrice > 0 && specialPrice != price">
            <b>MRP :</b>
            <span class="old-price text-strike text-muted font-16px">
              {{ price | currencyConvert }}{{units}}
            </span>
          </span>
        </span>

        <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
          <span *ngIf="displayMrpPrice">
            <b>MRP :</b>
          </span>
          <b> {{ price | currencyConvert }}{{units}}</b> (incl. of tax)
        </span>

        <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
          <b> {{ specialPrice | currencyConvert }}{{units}}</b> (incl. of tax)
        </span>

        <span *ngIf="!displayMrpPrice">
          <span *ngIf="specialPrice > 0 && specialPrice != price" class="old-price text-strike text-muted font-16px">
            {{ price | currencyConvert }}{{units}}
          </span>
        </span>



      </div>

      <div *ngIf="!inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
        fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

        <span *ngIf="specialPrice > 0 && specialPrice != price">
          <b *ngIf="displayMrpPrice">MRP :</b>
          <span class="old-price text-strike text-muted font-16px">
            {{ price | currencyConvert }}{{units}}
          </span>
        </span>

        <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
          <span *ngIf="displayMrpPrice">
            <b>MRP :</b>
          </span>
          <b> {{ price | currencyConvert }}{{units}}</b>
        </span>

        <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
          <b> {{ specialPrice | currencyConvert }}{{units}}</b>
          <span *ngIf="displayMrpPrice">
            (incl. of tax)
          </span>
        </span>
      </div>
    </div>
  </div>

  <div class="padding-lr10"
    [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }">
    <calsoft-controls [product]="configProductSelected" [data]="data" [variant1Name]="variant1Name"
      [variant2Name]="variant2Name" [variant1Value]="variant1Value" [variant2Value]="variant2Value"
      [configproduct]="config " [showCartIcon]="showCartIcon" [showCartIncremented]="showCartIncremented">
    </calsoft-controls>


  </div>
</div>


<div class="product-item product-elevat ext-center" *ngIf="productLayoutType==1">
  <div class="img-wrapper">

    <div class="pointer" class="product-item product-elevat ext-center">
      <div fxLayout="row" fxLayoutAlign="space-between center" style="position: sticky;">


        <div class="sale-label" *ngIf="discountPercentage > 0 && discountPercentage < 100">
          {{ discountPercentage | dynamicRound}}% Off
        </div>
      </div>
      <div class="front lable-block" fxLayoutAlign="center center">
        <a [ngClass]="{ 'collection-banner': !radius }">

          <img onerror="this.src='assets/images/products/placeholder.jpg';" [src]="image" [offset]="100"
            [style.border-radius]="radius" [alt]="name"
            [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }"
            class="padding-10" (click)="productShow(product)"
            [ngStyle.lt-md]="{visibility:isLoading?'hidden':'',height:height==0?'auto':heightMobile+'px',width:width==0?'auto':widthMobile+'px'}"
            [ngStyle.gt-sm]="{visibility:isLoading?'hidden':'',height:height==0?'auto':height+'px',width:width==0?'auto':width+'px'}"
            [name]="name" [image]="image" class="pointer" [name]="name">


        </a>
        <span *ngIf="product['type']!='Course'">
          <span *ngIf="this.product.type!='Booking'">
            <div *ngIf="outofstockEnabled==1">
              <div (click)="productShow(product)" class="outof centered1 title center" fxLayout="row"
                fxLayoutAlign="center center" *ngIf="(qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0)">
                <small fxLayoutAlign="center center" class="colorRed">OUT OF STOCK</small>
              </div>
            </div>
          </span>
        </span>

      </div>

      <ul class="product-thumb-list overflowImage">
        <li class="grid_thumb_img" [class.active]="ImageSrc == image" *ngFor="let image of imageListArray; let i=index">
          <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image)">

            <span *ngIf="image.includes('commonimages')">
              <img onerror="this.src='assets/images/products/placeholder.jpg';"
                [src]="serverPath7+image | safe: 'resourceUrl'">
            </span>
            <span *ngIf="image.includes('http')">
              <img onerror="this.src='assets/images/products/placeholder.jpg';" [src]="image | safe: 'resourceUrl'">
            </span>
            <span *ngIf="image.includes('medicineimages')">
              <img onerror="this.src='assets/images/products/placeholder.jpg';"
                [src]="commonImageApi+image | safe: 'resourceUrl'">
            </span>
            <span *ngIf="!image.includes('medicineimages')&&!image.includes('http')&&!image.includes('commonimages')">
              <img onerror="this.src='assets/images/products/placeholder.jpg';"
                [src]="bigImage+image | safe: 'resourceUrl'">
            </span>

          </a>
        </li>
      </ul>

      <div class="cart-info cart-wrap">


        <a title="Add to cart">
          <calsoft-controls [product]="configProductSelected" [data]="data" [variant1Name]="variant1Name"
            [variant2Name]="variant2Name" [variant1Value]="variant1Value" [variant2Value]="variant2Value"
            [configproduct]="config " [type]="'cartIcon'" [showCartIcon]="showCartIcon"
            [showCartIncremented]="showCartIncremented">
          </calsoft-controls>
        </a>

        <a *ngIf="product['type'] != 'Course' && product['type'] != 'Variants Subscription'" title="Wishlist">
          <calsoft-controls [data]="data" [variant1Name]="variant1Name" [variant2Name]="variant2Name"
            [variant1Value]="variant1Value" [variant2Value]="variant2Value" [product]="configProductSelected"
            [configproduct]="config" [type]="'newWishIcon'">
          </calsoft-controls>
        </a>

      </div>


    </div>
    <div class="product-detail">
      <div class="productPrice padding-rl10" (click)="productShow(product)" fxLayout="row" fxLayoutAlign="start center">

        <div *ngIf="!displayNameFully" [style.height]="data.categoryPageProductNameHeight"
          [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}" fxFlex>
          <div fxLayout="row">
            <div class="truncate-text" [fxFlex]="prescriptionRequired?96:100">

              <calsoft-translator [translateValue]="name" [data]="data" [type]="'products'"></calsoft-translator>

            </div>
            <img fxFlex height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
              alt="prescriptionRequired">
          </div>

        </div>

        <div fxFlex *ngIf="displayNameFully" [style.height]="data.categoryPageProductNameHeight"
          [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}">
          <div fxLayout="row">
            <calsoft-translator [fxFlex]="prescriptionRequired?96:100" [translateValue]="name" [data]="data"
              [type]="'productsNew'"></calsoft-translator>
            <img height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
              alt="prescriptionRequired">
          </div>
        </div>



      </div>


      <div [ngClass]="{
      padinf:
        configurableProductObject['type'] == 'Configurable' &&
        configurableProductObject['configurableProductDropdown'] != null
    }" class="padding-rl10" *ngIf="averageRating >= 1" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div class="review-Rating E_uFuv">
          {{ calculateAvgRating | number: "1.1-1" }}

          <span class="material-icons f11" style="font-size: 15px;"> star_rate </span>
        </div>
        <div>
          &#40;<span>{{ averageRating }}</span>&#41;
        </div>
      </div>
      <div [ngClass]="{ overlayProduct: qty <= 0 && manageStock == 1 }" *ngIf="averageRating == 0"
        style="padding-bottom: 20px"></div>

      <div *ngIf="showConfigurableDropdown == 1">

        <ng-container *ngIf="!showNewConfig; else newconfig">
          <div class="padding-rl10 A" fxLayout="row" *ngFor="let item of 
      configurationDropDownList | keyvalue">
            <mat-form-field style="
            font-size: 10px;
            height: 54px;
            padding: 0px;
            margin: 0px;
            padding-bottom: 0px;
          " appearance="outline" fxFlex="100">
              <mat-label style="font-size: 12px">
                {{ item.key.replace('_', ' ') }}
              </mat-label>
              <mat-select style="padding-bottom: 0.34375em; padding-top: 5px"
                (selectionChange)="getConfigProductId($event.value)" [disableOptionCentering]="true"
                [formControl]="dropDownValue">
                <mat-option style="font-size: 14px" [value]="object" *ngFor="let object of item.value">
                  {{ object | splitbyHash }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>

        <ng-template #newconfig>

          <div class="padding-rl10 A" fxLayout="row">
            <mat-form-field style="
            font-size: 10px;
            height: 54px;
            padding: 0px;
            margin: 0px;
            padding-bottom: 0px;
          " appearance="outline" fxFlex="100">
              <mat-label style="font-size: 12px">
                {{configurationDropDownList[0].key}}
              </mat-label>
              <mat-select [(ngModel)]="configurationDropDownList[firstIndex].value" [disableOptionCentering]="true">
                <mat-option (onSelectionChange)="getConfigProductIdNew($event,i,item,'firstIndex')" [value]="item.value"
                  *ngFor="let item of configurationDropDownList;let i=index;">
                  {{item.value}}
                </mat-option>
              </mat-select>

            </mat-form-field>
          </div>

          <div class="padding-rl10 A" fxLayout="row">
            <mat-form-field style="
        font-size: 10px;
        height: 54px;
        padding: 0px;
        margin: 0px;
        padding-bottom: 0px;
      " appearance="outline" fxFlex="100">
              <mat-label style="font-size: 12px">
                {{configurationDropDownList[0]
                ['recursuive'][0].key}}

              </mat-label>
              <mat-select [(ngModel)]="configurationDropDownList[firstIndex]
              .recursuive[secondIndex].value" [disableOptionCentering]="true">
                <mat-option (onSelectionChange)="getConfigProductIdNew($event,j,item1,'secondIndex')"
                  [value]="item1.value" *ngFor="let item1 of configurationDropDownList[firstIndex]
       ['recursuive'];let j=index;">
                  {{item1.value}}
                </mat-option>
              </mat-select>


            </mat-form-field>
          </div>


        </ng-template>
      </div>

      <div style="margin-bottom: 10px;" *ngIf="this.data.disablePrice==0">
        <div [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }">
          <div *ngIf="inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
            fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

            <span *ngIf="displayMrpPrice">
              <span *ngIf="specialPrice > 0 && specialPrice != price">
                <b>MRP :</b>
                <span class="old-price text-strike text-muted font-16px">
                  {{ price | currencyConvert }}{{units}}
                </span>
              </span>
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
              <span *ngIf="displayMrpPrice">
                <b>MRP :</b>
              </span>
              <b> {{ price | currencyConvert }}{{units}}</b> (incl. of tax)
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
              <b> {{ specialPrice | currencyConvert }}{{units}}</b> (incl. of tax)
            </span>

            <span *ngIf="!displayMrpPrice">
              <span *ngIf="specialPrice > 0 && specialPrice != price"
                class="old-price text-strike text-muted font-16px">
                {{ price | currencyConvert }}{{units}}
              </span>
            </span>



          </div>

          <div *ngIf="!inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
            fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

            <span *ngIf="specialPrice > 0 && specialPrice != price">
              <b *ngIf="displayMrpPrice">MRP :</b>
              <span class="old-price text-strike text-muted font-16px">
                {{ price | currencyConvert }}{{units}}
              </span>
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
              <span *ngIf="displayMrpPrice">
                <b>MRP :</b>
              </span>
              <b> {{ price | currencyConvert }}{{units}}</b>
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
              <b> {{ specialPrice | currencyConvert }}{{units}}</b>
              <span *ngIf="displayMrpPrice">
                (incl. of tax)
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="product-item  ext-center" *ngIf="productLayoutType==2">

  <div class="product">
    <figure class="product-media">

      <a [ngClass]="{ 'collection-banner': !radius }" (click)="productShow(product)">
        <img onerror="this.src='assets/images/products/placeholder.jpg';" [src]="image" [offset]="100" [alt]="name"
          [style.border-radius]="radius"
          [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }"
          class="padding-10" (click)="productShow(product)"
          [ngStyle.lt-md]="{visibility:isLoading?'hidden':'',height:height==0?'auto':heightMobile+'px',width:width==0?'auto':widthMobile+'px'}"
          [ngStyle.gt-sm]="{visibility:isLoading?'hidden':'',height:height==0?'auto':height+'px',width:width==0?'auto':width+'px'}"
          [name]="name" [image]="image" class="pointer">
      </a>
      <span *ngIf="product['type']!='Course'">
        <span *ngIf="this.product.type!='Booking'">
          <div *ngIf="outofstockEnabled==1">
            <div (click)="productShow(product)" class="outof centered1 title center" fxLayout="row"
              fxLayoutAlign="center center" *ngIf="(qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0)">
              <small fxLayoutAlign="center center" class="colorRed">OUT OF STOCK</small>
            </div>
          </div>
        </span>
      </span>

      <div class="product-action-vertical">

        <a title="Wishlist">
          <calsoft-controls [data]="data" [variant1Name]="variant1Name" [variant2Name]="variant2Name"
            [variant1Value]="variant1Value" [variant2Value]="variant2Value" [product]="configProductSelected"
            [configproduct]="config " [type]="'newWishIcon'">
          </calsoft-controls>
        </a>
      </div>


      <div class="product-action">
        <calsoft-controls [product]="configProductSelected" [data]="data" [variant1Name]="variant1Name"
          [variant2Name]="variant2Name" [variant1Value]="variant1Value" [variant2Value]="variant2Value"
          [configproduct]="config " [type]="'newAddTocart'" [showCartIcon]="showCartIcon"
          [showCartIncremented]="showCartIncremented">
        </calsoft-controls>
      </div>
    </figure>

    <div class="product-details">
      <div class="productPrice padding-rl10" (click)="productShow(product)" fxLayout="row" fxLayoutAlign="start center">

        <div *ngIf="!displayNameFully" [style.height]="data.categoryPageProductNameHeight"
          [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}" fxFlex>
          <div fxLayout="row">
            <div class="truncate-text" [fxFlex]="prescriptionRequired?96:100">

              <calsoft-translator [translateValue]="name" [data]="data" [type]="'products'"></calsoft-translator>

            </div>
            <img fxFlex height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
              alt="prescriptionRequired">
          </div>

        </div>

        <div fxFlex *ngIf="displayNameFully" [style.height]="data.categoryPageProductNameHeight"
          [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}">
          <div fxLayout="row">
            <calsoft-translator [fxFlex]="prescriptionRequired?96:100" [translateValue]="name" [data]="data"
              [type]="'productsNew'"></calsoft-translator>
            <img height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
              alt="prescriptionRequired">
          </div>
        </div>



      </div>


      <div [ngClass]="{
        padinf:
          configurableProductObject['type'] == 'Configurable' &&
          configurableProductObject['configurableProductDropdown'] != null
      }" class="padding-rl10" *ngIf="averageRating >= 1" fxLayout="row" fxLayoutAlign="start center"
        fxLayoutGap="10px">
        <div class="review-Rating E_uFuv">
          {{ calculateAvgRating | number: "1.1-1" }}

          <span class="material-icons f11" style="font-size: 15px;"> star_rate </span>
        </div>
        <div>
          &#40;<span>{{ averageRating }}</span>&#41;
        </div>
      </div>
      <div [ngClass]="{ overlayProduct: qty <= 0 && manageStock == 1 }" *ngIf="averageRating == 0"
        style="padding-bottom: 20px"></div>

      <div *ngIf="showConfigurableDropdown == 1">

        <ng-container *ngIf="!showNewConfig; else newconfig">
          <div class="padding-rl10 A" fxLayout="row" *ngFor="let item of 
        configurationDropDownList | keyvalue">
            <mat-form-field style="
              font-size: 10px;
              height: 54px;
              padding: 0px;
              margin: 0px;
              padding-bottom: 0px;
            " appearance="outline" fxFlex="100">
              <mat-label style="font-size: 12px">
                {{ item.key.replace('_', ' ') }}
              </mat-label>
              <mat-select style="padding-bottom: 0.34375em; padding-top: 5px"
                (selectionChange)="getConfigProductId($event.value)" [disableOptionCentering]="true"
                [formControl]="dropDownValue">
                <mat-option style="font-size: 14px" [value]="object" *ngFor="let object of item.value">
                  {{ object | splitbyHash }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>

        <ng-template #newconfig>

          <div class="padding-rl10 A" fxLayout="row">
            <mat-form-field style="
              font-size: 10px;
              height: 54px;
              padding: 0px;
              margin: 0px;
              padding-bottom: 0px;
            " appearance="outline" fxFlex="100">
              <mat-label style="font-size: 12px">
                {{configurationDropDownList[0].key}}
              </mat-label>
              <mat-select [(ngModel)]="configurationDropDownList[firstIndex].value" [disableOptionCentering]="true">
                <mat-option (onSelectionChange)="getConfigProductIdNew($event,i,item,'firstIndex')" [value]="item.value"
                  *ngFor="let item of configurationDropDownList;let i=index;">
                  {{item.value}}
                </mat-option>
              </mat-select>

            </mat-form-field>
          </div>

          <div class="padding-rl10 A" fxLayout="row">
            <mat-form-field style="
          font-size: 10px;
          height: 54px;
          padding: 0px;
          margin: 0px;
          padding-bottom: 0px;
        " appearance="outline" fxFlex="100">
              <mat-label style="font-size: 12px">
                {{configurationDropDownList[0]
                ['recursuive'][0].key}}

              </mat-label>
              <mat-select [(ngModel)]="configurationDropDownList[firstIndex]
                .recursuive[secondIndex].value" [disableOptionCentering]="true">
                <mat-option (onSelectionChange)="getConfigProductIdNew($event,j,item1,'secondIndex')"
                  [value]="item1.value" *ngFor="let item1 of configurationDropDownList[firstIndex]
         ['recursuive'];let j=index;">
                  {{item1.value}}
                </mat-option>
              </mat-select>


            </mat-form-field>
          </div>


        </ng-template>
      </div>

      <div style="padding-bottom: 10px;" *ngIf="this.data.disablePrice==0">
        <div [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }">
          <div *ngIf="inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
            fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

            <span *ngIf="displayMrpPrice">
              <span *ngIf="specialPrice > 0 && specialPrice != price">
                <b>MRP :</b>
                <span class="old-price text-strike text-muted font-16px">
                  {{ price | currencyConvert }}{{units}}
                </span>
              </span>
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
              <span *ngIf="displayMrpPrice">
                <b>MRP :</b>
              </span>
              <b> {{ price | currencyConvert }}{{units}}</b> (incl. of tax)
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
              <b> {{ specialPrice | currencyConvert }}{{units}}</b> (incl. of tax)
            </span>

            <span *ngIf="!displayMrpPrice">
              <span *ngIf="specialPrice > 0 && specialPrice != price"
                class="old-price text-strike text-muted font-16px">
                {{ price | currencyConvert }}{{units}}
              </span>
            </span>



          </div>

          <div *ngIf="!inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
            fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

            <span *ngIf="specialPrice > 0 && specialPrice != price">
              <b *ngIf="displayMrpPrice">MRP :</b>
              <span class="old-price text-strike text-muted font-16px">
                {{ price | currencyConvert }}{{units}}
              </span>
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
              <span *ngIf="displayMrpPrice">
                <b>MRP :</b>
              </span>
              <b> {{ price | currencyConvert }}{{units}}</b>
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
              <b> {{ specialPrice | currencyConvert }}{{units}}</b>
              <span *ngIf="displayMrpPrice">
                (incl. of tax)
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>

<div class="product-item  ext-center" *ngIf="productLayoutType==3">

  <div class="product images">
    <figure class="product-media">


      <a [ngClass]="{ 'collection-banner': !radius }" (click)="productShow(product)">
        <img onerror="this.src='assets/images/products/placeholder.jpg';" [src]="image" [offset]="100" [alt]="name"
          [ngClass]="{ 'collection-banner': !radius }"
          [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }"
          class="padding-10" (click)="productShow(product)"
          [ngStyle.lt-md]="{visibility:isLoading?'hidden':'',height:height==0?'auto':heightMobile+'px',width:width==0?'auto':widthMobile+'px'}"
          [ngStyle.gt-sm]="{visibility:isLoading?'hidden':'',height:height==0?'auto':height+'px',width:width==0?'auto':width+'px'}"
          [name]="name" [image]="image" class="pointer">
      </a>
      <span *ngIf="product['type']!='Course'">
        <span *ngIf="this.product.type!='Booking'">
          <div *ngIf="outofstockEnabled==1">
            <div (click)="productShow(product)" class="outof centered1 title center" fxLayout="row"
              fxLayoutAlign="center center" *ngIf="(qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0)">
              <small fxLayoutAlign="center center" class="colorRed">OUT OF STOCK</small>
            </div>
          </div>
        </span>
      </span>


    </figure>

    <div class="product-body text-center">
      <div class="product-details">
        <div class="productPrice padding-rl10" (click)="productShow(product)" fxLayout="row"
          fxLayoutAlign="start center">

          <div *ngIf="!displayNameFully" [style.height]="data.categoryPageProductNameHeight"
            [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}" fxFlex>
            <div fxLayout="row">
              <div class="truncate-text" [fxFlex]="prescriptionRequired?96:100">

                <calsoft-translator [translateValue]="name" [data]="data" [type]="'products'"></calsoft-translator>

              </div>
              <img fxFlex height="27px" src="assets/images/rx.svg" style="margin-bottom:0px"
                *ngIf="prescriptionRequired" alt="prescriptionRequired">
            </div>

          </div>

          <div fxFlex *ngIf="displayNameFully" [style.height]="data.categoryPageProductNameHeight"
            [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}">
            <div fxLayout="row">
              <calsoft-translator [fxFlex]="prescriptionRequired?96:100" [translateValue]="name" [data]="data"
                [type]="'productsNew'"></calsoft-translator>
              <img height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
                alt="prescriptionRequired">
            </div>
          </div>



        </div>


        <div [ngClass]="{
          padinf:
            configurableProductObject['type'] == 'Configurable' &&
            configurableProductObject['configurableProductDropdown'] != null
        }" class="padding-rl10" *ngIf="averageRating >= 1" fxLayout="row" fxLayoutAlign="start center"
          fxLayoutGap="10px">
          <div class="review-Rating E_uFuv">
            {{ calculateAvgRating | number: "1.1-1" }}

            <span class="material-icons f11" style="font-size: 15px;"> star_rate </span>
          </div>
          <div>
            &#40;<span>{{ averageRating }}</span>&#41;
          </div>
        </div>
        <div [ngClass]="{ overlayProduct: qty <= 0 && manageStock == 1 }" *ngIf="averageRating == 0"
          style="padding-bottom: 20px"></div>

        <div *ngIf="showConfigurableDropdown == 1">

          <ng-container *ngIf="!showNewConfig; else newconfig">
            <div class="padding-rl10 A" fxLayout="row" *ngFor="let item of 
          configurationDropDownList | keyvalue">
              <mat-form-field style="
                font-size: 10px;
                height: 54px;
                padding: 0px;
                margin: 0px;
                padding-bottom: 0px;
              " appearance="outline" fxFlex="100">
                <mat-label style="font-size: 12px">
                  {{ item.key.replace('_', ' ') }}
                </mat-label>
                <mat-select style="padding-bottom: 0.34375em; padding-top: 5px"
                  (selectionChange)="getConfigProductId($event.value)" [disableOptionCentering]="true"
                  [formControl]="dropDownValue">
                  <mat-option style="font-size: 14px" [value]="object" *ngFor="let object of item.value">
                    {{ object | splitbyHash }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>

          <ng-template #newconfig>

            <div class="padding-rl10 A" fxLayout="row">
              <mat-form-field style="
                font-size: 10px;
                height: 54px;
                padding: 0px;
                margin: 0px;
                padding-bottom: 0px;
              " appearance="outline" fxFlex="100">
                <mat-label style="font-size: 12px">
                  {{configurationDropDownList[0].key}}
                </mat-label>
                <mat-select [(ngModel)]="configurationDropDownList[firstIndex].value" [disableOptionCentering]="true">
                  <mat-option (onSelectionChange)="getConfigProductIdNew($event,i,item,'firstIndex')"
                    [value]="item.value" *ngFor="let item of configurationDropDownList;let i=index;">
                    {{item.value}}
                  </mat-option>
                </mat-select>

              </mat-form-field>
            </div>

            <div class="padding-rl10 A" fxLayout="row">
              <mat-form-field style="
            font-size: 10px;
            height: 54px;
            padding: 0px;
            margin: 0px;
            padding-bottom: 0px;
          " appearance="outline" fxFlex="100">
                <mat-label style="font-size: 12px">
                  {{configurationDropDownList[0]
                  ['recursuive'][0].key}}

                </mat-label>
                <mat-select [(ngModel)]="configurationDropDownList[firstIndex]
                  .recursuive[secondIndex].value" [disableOptionCentering]="true">
                  <mat-option (onSelectionChange)="getConfigProductIdNew($event,j,item1,'secondIndex')"
                    [value]="item1.value" *ngFor="let item1 of configurationDropDownList[firstIndex]
           ['recursuive'];let j=index;">
                    {{item1.value}}
                  </mat-option>
                </mat-select>


              </mat-form-field>
            </div>


          </ng-template>
        </div>

        <div style="padding-bottom: 10px;" *ngIf="this.data.disablePrice==0">
          <div [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }">
            <div *ngIf="inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
              fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

              <span *ngIf="displayMrpPrice">
                <span *ngIf="specialPrice > 0 && specialPrice != price">
                  <b>MRP :</b>
                  <span class="old-price text-strike text-muted font-16px">
                    {{ price | currencyConvert }}{{units}}
                  </span>
                </span>
              </span>

              <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
                <span *ngIf="displayMrpPrice">
                  <b>MRP :</b>
                </span>
                <b> {{ price | currencyConvert }}{{units}}</b> (incl. of tax)
              </span>

              <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
                <b> {{ specialPrice | currencyConvert }}{{units}}</b> (incl. of tax)
              </span>

              <span *ngIf="!displayMrpPrice">
                <span *ngIf="specialPrice > 0 && specialPrice != price"
                  class="old-price text-strike text-muted font-16px">
                  {{ price | currencyConvert }}{{units}}
                </span>
              </span>



            </div>

            <div *ngIf="!inclTaxEnabled" class="padding-rl10" (click)="productShow(product)"
              class="pointer padding-rl10" fxLayout="row" fxLayoutGap="10px"
              fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

              <span *ngIf="specialPrice > 0 && specialPrice != price">
                <b *ngIf="displayMrpPrice">MRP :</b>
                <span class="old-price text-strike text-muted font-16px">
                  {{ price | currencyConvert }}{{units}}
                </span>
              </span>

              <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
                <span *ngIf="displayMrpPrice">
                  <b>MRP :</b>
                </span>
                <b> {{ price | currencyConvert }}{{units}}</b>
              </span>

              <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
                <b> {{ specialPrice | currencyConvert }}{{units}}</b>
                <span *ngIf="displayMrpPrice">
                  (incl. of tax)
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>


      <div style="margin-bottom: 10px;" class="product-action">

        <calsoft-controls [product]="configProductSelected" [data]="data" [variant1Name]="variant1Name"
          [variant2Name]="variant2Name" [variant1Value]="variant1Value" [variant2Value]="variant2Value"
          [configproduct]="config " [type]="'type4addtocart'" [showCartIcon]="showCartIcon"
          [showCartIncremented]="showCartIncremented">
        </calsoft-controls>

      </div>
    </div>
  </div>
</div>

<div class="product-item  ext-center" *ngIf="productLayoutType==4">

  <div class="product">
    <figure class="product-media">

      <a [ngClass]="{ 'collection-banner': !radius }" (click)="productShow(product)">
        <img onerror="this.src='assets/images/products/placeholder.jpg';" [src]="image" [offset]="100" [alt]="name"
          [style.border-radius]="radius"
          [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }"
          class="padding-10" (click)="productShow(product)"
          [ngStyle.lt-md]="{visibility:isLoading?'hidden':'',height:height==0?'auto':heightMobile+'px',width:width==0?'auto':widthMobile+'px'}"
          [ngStyle.gt-sm]="{visibility:isLoading?'hidden':'',height:height==0?'auto':height+'px',width:width==0?'auto':width+'px'}"
          [name]="name" [image]="image" class="pointer">
      </a>
      <span *ngIf="product['type']!='Course'">
        <span *ngIf="this.product.type!='Booking'">
          <div *ngIf="outofstockEnabled==1">
            <div (click)="productShow(product)" class="outof centered1 title center" fxLayout="row"
              fxLayoutAlign="center center" *ngIf="(qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0)">
              <small fxLayoutAlign="center center" class="colorRed">OUT OF STOCK</small>
            </div>
          </div>
        </span>
      </span>

      <div class="product-action-vertical">

        <a title="Wishlist">
          <calsoft-controls [data]="data" [variant1Name]="variant1Name" [variant2Name]="variant2Name"
            [variant1Value]="variant1Value" [variant2Value]="variant2Value" [product]="configProductSelected"
            [configproduct]="config " [type]="'newWishIcon'">
          </calsoft-controls>
        </a>
      </div>


      <div class="product-action2">
        <calsoft-controls [product]="configProductSelected" [data]="data" [variant1Name]="variant1Name"
          [variant2Name]="variant2Name" [variant1Value]="variant1Value" [variant2Value]="variant2Value"
          [configproduct]="config " [type]="'newAddTocart'" [showCartIcon]="showCartIcon"
          [showCartIncremented]="showCartIncremented">
        </calsoft-controls>
      </div>
    </figure>

    <div class="product-details">
      <div class="productPrice padding-rl10" (click)="productShow(product)" fxLayout="row" fxLayoutAlign="start center">

        <div *ngIf="!displayNameFully" [style.height]="data.categoryPageProductNameHeight"
          [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}" fxFlex>
          <div fxLayout="row">
            <div class="truncate-text" [fxFlex]="prescriptionRequired?96:100">

              <calsoft-translator [translateValue]="name" [data]="data" [type]="'products'"></calsoft-translator>

            </div>
            <img fxFlex height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
              alt="prescriptionRequired">
          </div>

        </div>

        <div fxFlex *ngIf="displayNameFully" [style.height]="data.categoryPageProductNameHeight"
          [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}">
          <div fxLayout="row">
            <calsoft-translator [fxFlex]="prescriptionRequired?96:100" [translateValue]="name" [data]="data"
              [type]="'productsNew'"></calsoft-translator>
            <img height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
              alt="prescriptionRequired">
          </div>
        </div>



      </div>


      <div [ngClass]="{
        padinf:
          configurableProductObject['type'] == 'Configurable' &&
          configurableProductObject['configurableProductDropdown'] != null
      }" class="padding-rl10" *ngIf="averageRating >= 1" fxLayout="row" fxLayoutAlign="start center"
        fxLayoutGap="10px">
        <div class="review-Rating E_uFuv">
          {{ calculateAvgRating | number: "1.1-1" }}

          <span class="material-icons f11" style="font-size: 15px;"> star_rate </span>
        </div>
        <div>
          &#40;<span>{{ averageRating }}</span>&#41;
        </div>
      </div>
      <div [ngClass]="{ overlayProduct: qty <= 0 && manageStock == 1 }" *ngIf="averageRating == 0"
        style="padding-bottom: 20px"></div>

      <div *ngIf="showConfigurableDropdown == 1">

        <ng-container *ngIf="!showNewConfig; else newconfig">
          <div class="padding-rl10 A" fxLayout="row" *ngFor="let item of 
        configurationDropDownList | keyvalue">
            <mat-form-field style="
              font-size: 10px;
              height: 54px;
              padding: 0px;
              margin: 0px;
              padding-bottom: 0px;
            " appearance="outline" fxFlex="100">
              <mat-label style="font-size: 12px">
                {{ item.key.replace('_', ' ') }}
              </mat-label>
              <mat-select style="padding-bottom: 0.34375em; padding-top: 5px"
                (selectionChange)="getConfigProductId($event.value)" [disableOptionCentering]="true"
                [formControl]="dropDownValue">
                <mat-option style="font-size: 14px" [value]="object" *ngFor="let object of item.value">
                  {{ object | splitbyHash }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>

        <ng-template #newconfig>

          <div class="padding-rl10 A" fxLayout="row">
            <mat-form-field style="
              font-size: 10px;
              height: 54px;
              padding: 0px;
              margin: 0px;
              padding-bottom: 0px;
            " appearance="outline" fxFlex="100">
              <mat-label style="font-size: 12px">
                {{configurationDropDownList[0].key}}
              </mat-label>
              <mat-select [(ngModel)]="configurationDropDownList[firstIndex].value" [disableOptionCentering]="true">
                <mat-option (onSelectionChange)="getConfigProductIdNew($event,i,item,'firstIndex')" [value]="item.value"
                  *ngFor="let item of configurationDropDownList;let i=index;">
                  {{item.value}}
                </mat-option>
              </mat-select>

            </mat-form-field>
          </div>

          <div class="padding-rl10 A" fxLayout="row">
            <mat-form-field style="
          font-size: 10px;
          height: 54px;
          padding: 0px;
          margin: 0px;
          padding-bottom: 0px;
        " appearance="outline" fxFlex="100">
              <mat-label style="font-size: 12px">
                {{configurationDropDownList[0]
                ['recursuive'][0].key}}

              </mat-label>
              <mat-select [(ngModel)]="configurationDropDownList[firstIndex]
                .recursuive[secondIndex].value" [disableOptionCentering]="true">
                <mat-option (onSelectionChange)="getConfigProductIdNew($event,j,item1,'secondIndex')"
                  [value]="item1.value" *ngFor="let item1 of configurationDropDownList[firstIndex]
         ['recursuive'];let j=index;">
                  {{item1.value}}
                </mat-option>
              </mat-select>


            </mat-form-field>
          </div>


        </ng-template>
      </div>

      <div style="padding-bottom: 10px;" *ngIf="this.data.disablePrice==0">
        <div [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }">
          <div *ngIf="inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
            fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

            <span *ngIf="displayMrpPrice">
              <span *ngIf="specialPrice > 0 && specialPrice != price">
                <b>MRP :</b>
                <span class="old-price text-strike text-muted font-16px">
                  {{ price | currencyConvert }}{{units}}
                </span>
              </span>
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
              <span *ngIf="displayMrpPrice">
                <b>MRP :</b>
              </span>
              <b> {{ price | currencyConvert }}{{units}}</b> (incl. of tax)
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
              <b> {{ specialPrice | currencyConvert }}{{units}}</b> (incl. of tax)
            </span>

            <span *ngIf="!displayMrpPrice">
              <span *ngIf="specialPrice > 0 && specialPrice != price"
                class="old-price text-strike text-muted font-16px">
                {{ price | currencyConvert }}{{units}}
              </span>
            </span>



          </div>

          <div *ngIf="!inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
            fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

            <span *ngIf="specialPrice > 0 && specialPrice != price">
              <b *ngIf="displayMrpPrice">MRP :</b>
              <span class="old-price text-strike text-muted font-16px">
                {{ price | currencyConvert }}{{units}}
              </span>
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
              <span *ngIf="displayMrpPrice">
                <b>MRP :</b>
              </span>
              <b> {{ price | currencyConvert }}{{units}}</b>
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
              <b> {{ specialPrice | currencyConvert }}{{units}}</b>
              <span *ngIf="displayMrpPrice">
                (incl. of tax)
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>

<div class="product-item  ext-center" *ngIf="productLayoutType==5">

  <div class="product">
    <figure class="product-media">

      <a [ngClass]="{ 'collection-banner': !radius }" (click)="productShow(product)">
        <img onerror="this.src='assets/images/products/placeholder.jpg';" [src]="image" [offset]="100" [alt]="name"
          [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }"
          class="padding-10" (click)="productShow(product)"
          [ngStyle.lt-md]="{visibility:isLoading?'hidden':'',height:height==0?'auto':heightMobile+'px',width:width==0?'auto':widthMobile+'px'}"
          [ngStyle.gt-sm]="{visibility:isLoading?'hidden':'',height:height==0?'auto':height+'px',width:width==0?'auto':width+'px'}"
          [name]="name" [image]="image" class="pointer">
      </a>
      <span *ngIf="product['type']!='Course'">
        <span *ngIf="this.product.type!='Booking'">
          <div *ngIf="outofstockEnabled==1">
            <div (click)="productShow(product)" class="outof centered1 title center" fxLayout="row"
              fxLayoutAlign="center center" *ngIf="(qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0)">
              <small fxLayoutAlign="center center" class="colorRed">OUT OF STOCK</small>
            </div>
          </div>
        </span>
      </span>


      <div class="product-action-vertical1 product-action_2">

        <calsoft-controls [data]="data" [variant1Name]="variant1Name" [variant2Name]="variant2Name"
          [variant1Value]="variant1Value" [variant2Value]="variant2Value" [product]="configProductSelected"
          [configproduct]="config " [type]="'type5addtocart'">
        </calsoft-controls>

      </div>



    </figure>

    <div class="product-details">
      <div class="productPrice padding-rl10" (click)="productShow(product)" fxLayout="row" fxLayoutAlign="start center">

        <div *ngIf="!displayNameFully" [style.height]="data.categoryPageProductNameHeight"
          [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}" fxFlex>
          <div fxLayout="row">
            <div class="truncate-text" [fxFlex]="prescriptionRequired?96:100">

              <calsoft-translator [translateValue]="name" [data]="data" [type]="'products'"></calsoft-translator>

            </div>
            <img fxFlex height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
              alt="prescriptionRequired">
          </div>

        </div>

        <div fxFlex *ngIf="displayNameFully" [style.height]="data.categoryPageProductNameHeight"
          [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}">
          <div fxLayout="row">
            <calsoft-translator [fxFlex]="prescriptionRequired?96:100" [translateValue]="name" [data]="data"
              [type]="'productsNew'"></calsoft-translator>
            <img height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
              alt="prescriptionRequired">
          </div>
        </div>



      </div>


      <div [ngClass]="{
        padinf:
          configurableProductObject['type'] == 'Configurable' &&
          configurableProductObject['configurableProductDropdown'] != null
      }" class="padding-rl10" *ngIf="averageRating >= 1" fxLayout="row" fxLayoutAlign="start center"
        fxLayoutGap="10px">
        <div class="review-Rating E_uFuv">
          {{ calculateAvgRating | number: "1.1-1" }}

          <span class="material-icons f11" style="font-size: 15px;"> star_rate </span>
        </div>
        <div>
          &#40;<span>{{ averageRating }}</span>&#41;
        </div>
      </div>
      <div [ngClass]="{ overlayProduct: qty <= 0 && manageStock == 1 }" *ngIf="averageRating == 0"
        style="padding-bottom: 20px"></div>

      <div *ngIf="showConfigurableDropdown == 1">

        <ng-container *ngIf="!showNewConfig; else newconfig">
          <div class="padding-rl10 A" fxLayout="row" *ngFor="let item of 
        configurationDropDownList | keyvalue">
            <mat-form-field style="
              font-size: 10px;
              height: 54px;
              padding: 0px;
              margin: 0px;
              padding-bottom: 0px;
            " appearance="outline" fxFlex="100">
              <mat-label style="font-size: 12px">
                {{ item.key.replace('_', ' ') }}
              </mat-label>
              <mat-select style="padding-bottom: 0.34375em; padding-top: 5px"
                (selectionChange)="getConfigProductId($event.value)" [disableOptionCentering]="true"
                [formControl]="dropDownValue">
                <mat-option style="font-size: 14px" [value]="object" *ngFor="let object of item.value">
                  {{ object | splitbyHash }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>

        <ng-template #newconfig>

          <div class="padding-rl10 A" fxLayout="row">
            <mat-form-field style="
              font-size: 10px;
              height: 54px;
              padding: 0px;
              margin: 0px;
              padding-bottom: 0px;
            " appearance="outline" fxFlex="100">
              <mat-label style="font-size: 12px">
                {{configurationDropDownList[0].key}}
              </mat-label>
              <mat-select [(ngModel)]="configurationDropDownList[firstIndex].value" [disableOptionCentering]="true">
                <mat-option (onSelectionChange)="getConfigProductIdNew($event,i,item,'firstIndex')" [value]="item.value"
                  *ngFor="let item of configurationDropDownList;let i=index;">
                  {{item.value}}
                </mat-option>
              </mat-select>

            </mat-form-field>
          </div>

          <div class="padding-rl10 A" fxLayout="row">
            <mat-form-field style="
          font-size: 10px;
          height: 54px;
          padding: 0px;
          margin: 0px;
          padding-bottom: 0px;
        " appearance="outline" fxFlex="100">
              <mat-label style="font-size: 12px">
                {{configurationDropDownList[0]
                ['recursuive'][0].key}}

              </mat-label>
              <mat-select [(ngModel)]="configurationDropDownList[firstIndex]
                .recursuive[secondIndex].value" [disableOptionCentering]="true">
                <mat-option (onSelectionChange)="getConfigProductIdNew($event,j,item1,'secondIndex')"
                  [value]="item1.value" *ngFor="let item1 of configurationDropDownList[firstIndex]
         ['recursuive'];let j=index;">
                  {{item1.value}}
                </mat-option>
              </mat-select>


            </mat-form-field>
          </div>


        </ng-template>
      </div>

      <div style="padding-bottom: 10px;" *ngIf="this.data.disablePrice==0">
        <div [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }">
          <div *ngIf="inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
            fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

            <span *ngIf="displayMrpPrice">
              <span *ngIf="specialPrice > 0 && specialPrice != price">
                <b>MRP :</b>
                <span class="old-price text-strike text-muted font-16px">
                  {{ price | currencyConvert }}{{units}}
                </span>
              </span>
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
              <span *ngIf="displayMrpPrice">
                <b>MRP :</b>
              </span>
              <b> {{ price | currencyConvert }}{{units}}</b> (incl. of tax)
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
              <b> {{ specialPrice | currencyConvert }}{{units}}</b> (incl. of tax)
            </span>

            <span *ngIf="!displayMrpPrice">
              <span *ngIf="specialPrice > 0 && specialPrice != price"
                class="old-price text-strike text-muted font-16px">
                {{ price | currencyConvert }}{{units}}
              </span>
            </span>



          </div>

          <div *ngIf="!inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
            fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

            <span *ngIf="specialPrice > 0 && specialPrice != price">
              <b *ngIf="displayMrpPrice">MRP :</b>
              <span class="old-price text-strike text-muted font-16px">
                {{ price | currencyConvert }}{{units}}
              </span>
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
              <span *ngIf="displayMrpPrice">
                <b>MRP :</b>
              </span>
              <b> {{ price | currencyConvert }}{{units}}</b>
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
              <b> {{ specialPrice | currencyConvert }}{{units}}</b>
              <span *ngIf="displayMrpPrice">
                (incl. of tax)
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>

<div class="product-item  ext-center" *ngIf="productLayoutType==6">

  <div class="product">
    <figure class="product-media ">

      <a [ngClass]="{ 'collection-banner': !radius }" (click)="productShow(product)" class="imgbkg">
        <img onerror="this.src='assets/images/products/placeholder.jpg';" [src]="image" [offset]="100" [alt]="name"
          [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }"
          class="padding-10" (click)="productShow(product)"
          [ngStyle.lt-md]="{visibility:isLoading?'hidden':'',height:height==0?'auto':heightMobile+'px',width:width==0?'auto':widthMobile+'px'}"
          [ngStyle.gt-sm]="{visibility:isLoading?'hidden':'',height:height==0?'auto':height+'px',width:width==0?'auto':width+'px'}"
          [name]="name" [image]="image" class="pointer ">
      </a>
      <span *ngIf="product['type']!='Course'">
        <span *ngIf="this.product.type!='Booking'">
          <div *ngIf="outofstockEnabled==1">
            <div (click)="productShow(product)" class="outof centered1 title center" fxLayout="row"
              fxLayoutAlign="center center" *ngIf="(qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0)">
              <small fxLayoutAlign="center center" class="colorRed">OUT OF STOCK</small>
            </div>
          </div>
        </span>
      </span>

      <div class="product-action3">
        <calsoft-controls [data]="data" [variant1Name]="variant1Name" [variant2Name]="variant2Name"
          [variant1Value]="variant1Value" [variant2Value]="variant2Value" [product]="configProductSelected"
          [configproduct]="config " [type]="'type5addtocart'">
        </calsoft-controls>
      </div>

    </figure>
    <div class="product-details">
      <div class="productPrice padding-rl10" (click)="productShow(product)" fxLayout="row" fxLayoutAlign="start center">

        <div *ngIf="!displayNameFully" [style.height]="data.categoryPageProductNameHeight"
          [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}" fxFlex>
          <div fxLayout="row">
            <div class="truncate-text" [fxFlex]="prescriptionRequired?96:100">

              <calsoft-translator [translateValue]="name" [data]="data" [type]="'products'"></calsoft-translator>

            </div>
            <img fxFlex height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
              alt="prescriptionRequired">
          </div>

        </div>

        <div fxFlex *ngIf="displayNameFully" [style.height]="data.categoryPageProductNameHeight"
          [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}">
          <div fxLayout="row">
            <calsoft-translator [fxFlex]="prescriptionRequired?96:100" [translateValue]="name" [data]="data"
              [type]="'productsNew'"></calsoft-translator>
            <img height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
              alt="prescriptionRequired">
          </div>
        </div>



      </div>


      <div [ngClass]="{
        padinf:
          configurableProductObject['type'] == 'Configurable' &&
          configurableProductObject['configurableProductDropdown'] != null
      }" class="padding-rl10" *ngIf="averageRating >= 1" fxLayout="row" fxLayoutAlign="start center"
        fxLayoutGap="10px">
        <div class="review-Rating E_uFuv">
          {{ calculateAvgRating | number: "1.1-1" }}

          <span class="material-icons f11" style="font-size: 15px;"> star_rate </span>
        </div>
        <div>
          &#40;<span>{{ averageRating }}</span>&#41;
        </div>
      </div>
      <div [ngClass]="{ overlayProduct: qty <= 0 && manageStock == 1 }" *ngIf="averageRating == 0"
        style="padding-bottom: 20px"></div>

      <div *ngIf="showConfigurableDropdown == 1">

        <ng-container *ngIf="!showNewConfig; else newconfig">
          <div class="padding-rl10 A" fxLayout="row" *ngFor="let item of 
        configurationDropDownList | keyvalue">
            <mat-form-field style="
              font-size: 10px;
              height: 54px;
              padding: 0px;
              margin: 0px;
              padding-bottom: 0px;
            " appearance="outline" fxFlex="100">
              <mat-label style="font-size: 12px">
                {{ item.key.replace('_', ' ') }}
              </mat-label>
              <mat-select style="padding-bottom: 0.34375em; padding-top: 5px"
                (selectionChange)="getConfigProductId($event.value)" [disableOptionCentering]="true"
                [formControl]="dropDownValue">
                <mat-option style="font-size: 14px" [value]="object" *ngFor="let object of item.value">
                  {{ object | splitbyHash }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>

        <ng-template #newconfig>

          <div class="padding-rl10 A" fxLayout="row">
            <mat-form-field style="
              font-size: 10px;
              height: 54px;
              padding: 0px;
              margin: 0px;
              padding-bottom: 0px;
            " appearance="outline" fxFlex="100">
              <mat-label style="font-size: 12px">
                {{configurationDropDownList[0].key}}
              </mat-label>
              <mat-select [(ngModel)]="configurationDropDownList[firstIndex].value" [disableOptionCentering]="true">
                <mat-option (onSelectionChange)="getConfigProductIdNew($event,i,item,'firstIndex')" [value]="item.value"
                  *ngFor="let item of configurationDropDownList;let i=index;">
                  {{item.value}}
                </mat-option>
              </mat-select>

            </mat-form-field>
          </div>

          <div class="padding-rl10 A" fxLayout="row">
            <mat-form-field style="
          font-size: 10px;
          height: 54px;
          padding: 0px;
          margin: 0px;
          padding-bottom: 0px;
        " appearance="outline" fxFlex="100">
              <mat-label style="font-size: 12px">
                {{configurationDropDownList[0]
                ['recursuive'][0].key}}

              </mat-label>
              <mat-select [(ngModel)]="configurationDropDownList[firstIndex]
                .recursuive[secondIndex].value" [disableOptionCentering]="true">
                <mat-option (onSelectionChange)="getConfigProductIdNew($event,j,item1,'secondIndex')"
                  [value]="item1.value" *ngFor="let item1 of configurationDropDownList[firstIndex]
         ['recursuive'];let j=index;">
                  {{item1.value}}
                </mat-option>
              </mat-select>


            </mat-form-field>
          </div>


        </ng-template>
      </div>

      <div style="padding-bottom: 10px;" *ngIf="this.data.disablePrice==0">
        <div [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }">
          <div *ngIf="inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
            fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

            <span *ngIf="displayMrpPrice">
              <span *ngIf="specialPrice > 0 && specialPrice != price">
                <b>MRP :</b>
                <span class="old-price text-strike text-muted font-16px">
                  {{ price | currencyConvert }}{{units}}
                </span>
              </span>
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
              <span *ngIf="displayMrpPrice">
                <b>MRP :</b>
              </span>
              <b> {{ price | currencyConvert }}{{units}}</b> (incl. of tax)
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
              <b> {{ specialPrice | currencyConvert }}{{units}}</b> (incl. of tax)
            </span>

            <span *ngIf="!displayMrpPrice">
              <span *ngIf="specialPrice > 0 && specialPrice != price"
                class="old-price text-strike text-muted font-16px">
                {{ price | currencyConvert }}{{units}}
              </span>
            </span>



          </div>

          <div *ngIf="!inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
            fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

            <span *ngIf="specialPrice > 0 && specialPrice != price">
              <b *ngIf="displayMrpPrice">MRP :</b>
              <span class="old-price text-strike text-muted font-16px">
                {{ price | currencyConvert }}{{units}}
              </span>
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
              <span *ngIf="displayMrpPrice">
                <b>MRP :</b>
              </span>
              <b> {{ price | currencyConvert }}{{units}}</b>
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
              <b> {{ specialPrice | currencyConvert }}{{units}}</b>
              <span *ngIf="displayMrpPrice">
                (incl. of tax)
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
<div class="product-item product-elevat ext-center" *ngIf="productLayoutType==7">
  <div class="img-wrapper">

    <div class="pointer" class="product-item product-elevat ext-center">
      <div fxLayout="row" fxLayoutAlign="space-between center" style="position: sticky;">


        <div class="sale-label" *ngIf="discountPercentage > 0 && discountPercentage < 100">
          {{ discountPercentage | dynamicRound}}% Off
        </div>
      </div>
      <div class="front lable-block" fxLayoutAlign="center center">
        <a [ngClass]="{ 'collection-banner': !radius }">

          <img onerror="this.src='assets/images/products/placeholder.jpg';" [src]="image" [offset]="100" [alt]="name"
            [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }"
            class="padding-10" (click)="productShow(product)"
            [ngStyle.lt-md]="{visibility:isLoading?'hidden':'',height:height==0?'auto':heightMobile+'px',width:width==0?'auto':widthMobile+'px'}"
            [ngStyle.gt-sm]="{visibility:isLoading?'hidden':'',height:height==0?'auto':height+'px',width:width==0?'auto':width+'px'}"
            [name]="name" [image]="image" class="pointer" [name]="name">


        </a>
        <span *ngIf="product['type']!='Course'">
          <span *ngIf="this.product.type!='Booking'">
            <div *ngIf="outofstockEnabled==1">
              <div (click)="productShow(product)" class="outof centered1 title center" fxLayout="row"
                fxLayoutAlign="center center" *ngIf="(qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0)">
                <small fxLayoutAlign="center center" class="colorRed">OUT OF STOCK</small>
              </div>
            </div>
          </span>
        </span>

      </div>

      <!-- <ul class="product-thumb-list overflowImage">
        <li class="grid_thumb_img" [class.active]="ImageSrc == image" *ngFor="let image of imageListArray; let i=index">
          <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image)">

            <span *ngIf="image.includes('commonimages')">
              <img onerror="this.src='assets/images/products/placeholder.jpg';"
                [src]="serverPath7+image | safe: 'resourceUrl'">
            </span>
            <span *ngIf="image.includes('http')">
              <img onerror="this.src='assets/images/products/placeholder.jpg';"
                [src]="image | safe: 'resourceUrl'">
            </span>
            <span *ngIf="image.includes('medicineimages')">
              <img onerror="this.src='assets/images/products/placeholder.jpg';"
                [src]="commonImageApi+image | safe: 'resourceUrl'">
            </span>
            <span *ngIf="!image.includes('medicineimages')&&!image.includes('http')&&!image.includes('commonimages')">
              <img onerror="this.src='assets/images/products/placeholder.jpg';"
                [src]="bigImage+image | safe: 'resourceUrl'">
            </span>

          </a>
        </li>
      </ul> -->

      <div class="cart-info cart-wrap">


        <a title="Add to cart">
          <calsoft-controls [product]="configProductSelected" [data]="data" [variant1Name]="variant1Name"
            [variant2Name]="variant2Name" [variant1Value]="variant1Value" [variant2Value]="variant2Value"
            [configproduct]="config " [type]="'cartIcon'" [showCartIcon]="showCartIcon"
            [showCartIncremented]="showCartIncremented">
          </calsoft-controls>
        </a>

        <a title="Wishlist">
          <calsoft-controls [data]="data" [variant1Name]="variant1Name" [variant2Name]="variant2Name"
            [variant1Value]="variant1Value" [variant2Value]="variant2Value" [product]="configProductSelected"
            [configproduct]="config " [type]="'newWishIcon'">
          </calsoft-controls>
        </a>
      </div>


    </div>
    <div class="product-detail">
      <div class="productPrice padding-rl10" (click)="productShow(product)" fxLayout="row" fxLayoutAlign="start center">

        <div *ngIf="!displayNameFully" [style.height]="data.categoryPageProductNameHeight"
          [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}" fxFlex>
          <div fxLayout="row">
            <div class="truncate-text" [fxFlex]="prescriptionRequired?96:100">

              <calsoft-translator [translateValue]="name" [data]="data" [type]="'products'"></calsoft-translator>

            </div>
            <img fxFlex height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
              alt="prescriptionRequired">
          </div>

        </div>

        <div fxFlex *ngIf="displayNameFully" [style.height]="data.categoryPageProductNameHeight"
          [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}">
          <div fxLayout="row">
            <calsoft-translator [fxFlex]="prescriptionRequired?96:100" [translateValue]="name" [data]="data"
              [type]="'productsNew'"></calsoft-translator>
            <img height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
              alt="prescriptionRequired">
          </div>
        </div>



      </div>


      <div [ngClass]="{
      padinf:
        configurableProductObject['type'] == 'Configurable' &&
        configurableProductObject['configurableProductDropdown'] != null
    }" class="padding-rl10" *ngIf="averageRating >= 1" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div class="review-Rating E_uFuv">
          {{ calculateAvgRating | number: "1.1-1" }}

          <span class="material-icons f11" style="font-size: 15px;"> star_rate </span>
        </div>
        <div>
          &#40;<span>{{ averageRating }}</span>&#41;
        </div>
      </div>
      <div [ngClass]="{ overlayProduct: qty <= 0 && manageStock == 1 }" *ngIf="averageRating == 0"
        style="padding-bottom: 20px"></div>

      <div *ngIf="showConfigurableDropdown == 1">

        <ng-container *ngIf="!showNewConfig; else newconfig">
          <div class="padding-rl10 A" fxLayout="row" *ngFor="let item of 
      configurationDropDownList | keyvalue">
            <mat-form-field style="
            font-size: 10px;
            height: 54px;
            padding: 0px;
            margin: 0px;
            padding-bottom: 0px;
          " appearance="outline" fxFlex="100">
              <mat-label style="font-size: 12px">
                {{ item.key.replace('_', ' ') }}
              </mat-label>
              <mat-select style="padding-bottom: 0.34375em; padding-top: 5px"
                (selectionChange)="getConfigProductId($event.value)" [disableOptionCentering]="true"
                [formControl]="dropDownValue">
                <mat-option style="font-size: 14px" [value]="object" *ngFor="let object of item.value">
                  {{ object | splitbyHash }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>

        <ng-template #newconfig>

          <div class="padding-rl10 A" fxLayout="row">
            <mat-form-field style="
            font-size: 10px;
            height: 54px;
            padding: 0px;
            margin: 0px;
            padding-bottom: 0px;
          " appearance="outline" fxFlex="100">
              <mat-label style="font-size: 12px">
                {{configurationDropDownList[0].key}}
              </mat-label>
              <mat-select [(ngModel)]="configurationDropDownList[firstIndex].value" [disableOptionCentering]="true">
                <mat-option (onSelectionChange)="getConfigProductIdNew($event,i,item,'firstIndex')" [value]="item.value"
                  *ngFor="let item of configurationDropDownList;let i=index;">
                  {{item.value}}
                </mat-option>
              </mat-select>

            </mat-form-field>
          </div>

          <div class="padding-rl10 A" fxLayout="row">
            <mat-form-field style="
        font-size: 10px;
        height: 54px;
        padding: 0px;
        margin: 0px;
        padding-bottom: 0px;
      " appearance="outline" fxFlex="100">
              <mat-label style="font-size: 12px">
                {{configurationDropDownList[0]
                ['recursuive'][0].key}}

              </mat-label>
              <mat-select [(ngModel)]="configurationDropDownList[firstIndex]
              .recursuive[secondIndex].value" [disableOptionCentering]="true">
                <mat-option (onSelectionChange)="getConfigProductIdNew($event,j,item1,'secondIndex')"
                  [value]="item1.value" *ngFor="let item1 of configurationDropDownList[firstIndex]
       ['recursuive'];let j=index;">
                  {{item1.value}}
                </mat-option>
              </mat-select>


            </mat-form-field>
          </div>


        </ng-template>
      </div>

      <div style="margin-bottom: 10px;" *ngIf="this.data.disablePrice==0">
        <div [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }">
          <div *ngIf="inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
            fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

            <span *ngIf="displayMrpPrice">
              <span *ngIf="specialPrice > 0 && specialPrice != price">
                <b>MRP :</b>
                <span class="old-price text-strike text-muted font-16px">
                  {{ price | currencyConvert }}{{units}}
                </span>
              </span>
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
              <span *ngIf="displayMrpPrice">
                <b>MRP :</b>
              </span>
              <b> {{ price | currencyConvert }}{{units}}</b> (incl. of tax)
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
              <b> {{ specialPrice | currencyConvert }}{{units}}</b> (incl. of tax)
            </span>

            <span *ngIf="!displayMrpPrice">
              <span *ngIf="specialPrice > 0 && specialPrice != price"
                class="old-price text-strike text-muted font-16px">
                {{ price | currencyConvert }}{{units}}
              </span>
            </span>



          </div>

          <div *ngIf="!inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
            fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

            <span *ngIf="specialPrice > 0 && specialPrice != price">
              <b *ngIf="displayMrpPrice">MRP :</b>
              <span class="old-price text-strike text-muted font-16px">
                {{ price | currencyConvert }}{{units}}
              </span>
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
              <span *ngIf="displayMrpPrice">
                <b>MRP :</b>
              </span>
              <b> {{ price | currencyConvert }}{{units}}</b>
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
              <b> {{ specialPrice | currencyConvert }}{{units}}</b>
              <span *ngIf="displayMrpPrice">
                (incl. of tax)
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="product-item  ext-center" *ngIf="productLayoutType==8">

  <div class="product">
    <figure class="product-media">

      <a [ngClass]="{ 'collection-banner': !radius }" (click)="productShow(product)">
        <img onerror="this.src='assets/images/products/placeholder.jpg';" [src]="image" [offset]="100" [alt]="name"
          [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }"
          class="padding-10" (click)="productShow(product)"
          [ngStyle.lt-md]="{visibility:isLoading?'hidden':'',height:height==0?'auto':heightMobile+'px',width:width==0?'auto':widthMobile+'px'}"
          [ngStyle.gt-sm]="{visibility:isLoading?'hidden':'',height:height==0?'auto':height+'px',width:width==0?'auto':width+'px'}"
          [name]="name" [image]="image" class="pointer">
      </a>
      <span *ngIf="product['type']!='Course'">
        <span *ngIf="this.product.type!='Booking'">
          <div *ngIf="outofstockEnabled==1">
            <div (click)="productShow(product)" class="outof centered1 title center" fxLayout="row"
              fxLayoutAlign="center center" *ngIf="(qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0)">
              <small fxLayoutAlign="center center" class="colorRed">OUT OF STOCK</small>
            </div>
          </div>
        </span>
      </span>

      <div class="product-action-vertical1 product-action_3">

        <calsoft-controls [data]="data" [variant1Name]="variant1Name" [variant2Name]="variant2Name"
          [variant1Value]="variant1Value" [variant2Value]="variant2Value" [product]="configProductSelected"
          [configproduct]="config " [type]="'type5addtocart'">
        </calsoft-controls>

      </div>



    </figure>

    <div class="product-details">
      <div class="productPrice padding-rl10" (click)="productShow(product)" fxLayout="row" fxLayoutAlign="start center">

        <div *ngIf="!displayNameFully" [style.height]="data.categoryPageProductNameHeight"
          [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}" fxFlex>
          <div fxLayout="row">
            <div class="truncate-text" [fxFlex]="prescriptionRequired?96:100">

              <calsoft-translator [translateValue]="name" [data]="data" [type]="'products'"></calsoft-translator>

            </div>
            <img fxFlex height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
              alt="prescriptionRequired">
          </div>

        </div>

        <div fxFlex *ngIf="displayNameFully" [style.height]="data.categoryPageProductNameHeight"
          [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}">
          <div fxLayout="row">
            <calsoft-translator [fxFlex]="prescriptionRequired?96:100" [translateValue]="name" [data]="data"
              [type]="'productsNew'"></calsoft-translator>
            <img height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
              alt="prescriptionRequired">
          </div>
        </div>



      </div>


      <div [ngClass]="{
        padinf:
          configurableProductObject['type'] == 'Configurable' &&
          configurableProductObject['configurableProductDropdown'] != null
      }" class="padding-rl10" *ngIf="averageRating >= 1" fxLayout="row" fxLayoutAlign="start center"
        fxLayoutGap="10px">
        <div class="review-Rating E_uFuv">
          {{ calculateAvgRating | number: "1.1-1" }}

          <span class="material-icons f11" style="font-size: 15px;"> star_rate </span>
        </div>
        <div>
          &#40;<span>{{ averageRating }}</span>&#41;
        </div>
      </div>
      <div [ngClass]="{ overlayProduct: qty <= 0 && manageStock == 1 }" *ngIf="averageRating == 0"
        style="padding-bottom: 20px"></div>

      <div *ngIf="showConfigurableDropdown == 1">

        <ng-container *ngIf="!showNewConfig; else newconfig">
          <div class="padding-rl10 A" fxLayout="row" *ngFor="let item of 
        configurationDropDownList | keyvalue">
            <mat-form-field style="
              font-size: 10px;
              height: 54px;
              padding: 0px;
              margin: 0px;
              padding-bottom: 0px;
            " appearance="outline" fxFlex="100">
              <mat-label style="font-size: 12px">
                {{ item.key.replace('_', ' ') }}
              </mat-label>
              <mat-select style="padding-bottom: 0.34375em; padding-top: 5px"
                (selectionChange)="getConfigProductId($event.value)" [disableOptionCentering]="true"
                [formControl]="dropDownValue">
                <mat-option style="font-size: 14px" [value]="object" *ngFor="let object of item.value">
                  {{ object | splitbyHash }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>

        <ng-template #newconfig>

          <div class="padding-rl10 A" fxLayout="row">
            <mat-form-field style="
              font-size: 10px;
              height: 54px;
              padding: 0px;
              margin: 0px;
              padding-bottom: 0px;
            " appearance="outline" fxFlex="100">
              <mat-label style="font-size: 12px">
                {{configurationDropDownList[0].key}}
              </mat-label>
              <mat-select [(ngModel)]="configurationDropDownList[firstIndex].value" [disableOptionCentering]="true">
                <mat-option (onSelectionChange)="getConfigProductIdNew($event,i,item,'firstIndex')" [value]="item.value"
                  *ngFor="let item of configurationDropDownList;let i=index;">
                  {{item.value}}
                </mat-option>
              </mat-select>

            </mat-form-field>
          </div>

          <div class="padding-rl10 A" fxLayout="row">
            <mat-form-field style="
          font-size: 10px;
          height: 54px;
          padding: 0px;
          margin: 0px;
          padding-bottom: 0px;
        " appearance="outline" fxFlex="100">
              <mat-label style="font-size: 12px">
                {{configurationDropDownList[0]
                ['recursuive'][0].key}}

              </mat-label>
              <mat-select [(ngModel)]="configurationDropDownList[firstIndex]
                .recursuive[secondIndex].value" [disableOptionCentering]="true">
                <mat-option (onSelectionChange)="getConfigProductIdNew($event,j,item1,'secondIndex')"
                  [value]="item1.value" *ngFor="let item1 of configurationDropDownList[firstIndex]
         ['recursuive'];let j=index;">
                  {{item1.value}}
                </mat-option>
              </mat-select>


            </mat-form-field>
          </div>


        </ng-template>
      </div>

      <div style="padding-bottom: 10px;" *ngIf="this.data.disablePrice==0">
        <div [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }">
          <div *ngIf="inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
            fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

            <span *ngIf="displayMrpPrice">
              <span *ngIf="specialPrice > 0 && specialPrice != price">
                <b>MRP :</b>
                <span class="old-price text-strike text-muted font-16px">
                  {{ price | currencyConvert }}{{units}}
                </span>
              </span>
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
              <span *ngIf="displayMrpPrice">
                <b>MRP :</b>
              </span>
              <b> {{ price | currencyConvert }}{{units}}</b> (incl. of tax)
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
              <b> {{ specialPrice | currencyConvert }}{{units}}</b> (incl. of tax)
            </span>

            <span *ngIf="!displayMrpPrice">
              <span *ngIf="specialPrice > 0 && specialPrice != price"
                class="old-price text-strike text-muted font-16px">
                {{ price | currencyConvert }}{{units}}
              </span>
            </span>



          </div>

          <div *ngIf="!inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
            fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

            <span *ngIf="specialPrice > 0 && specialPrice != price">
              <b *ngIf="displayMrpPrice">MRP :</b>
              <span class="old-price text-strike text-muted font-16px">
                {{ price | currencyConvert }}{{units}}
              </span>
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
              <span *ngIf="displayMrpPrice">
                <b>MRP :</b>
              </span>
              <b> {{ price | currencyConvert }}{{units}}</b>
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
              <b> {{ specialPrice | currencyConvert }}{{units}}</b>
              <span *ngIf="displayMrpPrice">
                (incl. of tax)
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>

<div class="pointer" *ngIf="productLayoutType==9" class="product-item product-elevat ext-center">
  <div fxLayout="row" fxLayoutAlign="space-between center" style="position: sticky;">
    <calsoft-controls [data]="data" [variant1Name]="variant1Name" [variant2Name]="variant2Name"
      [variant1Value]="variant1Value" [variant2Value]="variant2Value" [product]="configProductSelected"
      [configproduct]="config " style="position: absolute; top: 0; left: 0" [type]="'wishonly'">
    </calsoft-controls>

    <div class="sale-label" *ngIf="discountPercentage > 0 && discountPercentage < 100">
      {{ discountPercentage | dynamicRound}}% Off
    </div>
  </div>

  <div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <calsoft-image-lazy-loading *ngIf="showAnimation" [radius]="'12px'"
        [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }" class="padding-10"
        (click)="productShow(product)" [width]="width" [imageType]="imageType" [heightMobile]="heightMobile"
        [widthMobile]="widthMobile" [height]="height" [type]="type" class="pointer" [name]="name" [image]="image">
      </calsoft-image-lazy-loading>


      <calsoft-image-lazy-main-mouse-over-main *ngIf="!showAnimation" [type]="type"
        [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }" class="padding-10"
        (click)="productShow(product)" [qty]="qty" [product]="product" [width]="width" [imageType]="imageType"
        [heightMobile]="heightMobile" [widthMobile]="widthMobile" [height]="height" [type]="type" class="pointer"
        [name]="name" [list]="list"></calsoft-image-lazy-main-mouse-over-main>

    </div>
    <span *ngIf="product['type']!='Course'">
      <span *ngIf="this.product.type!='Booking'">
        <div *ngIf="outofstockEnabled==1">
          <div (click)="productShow(product)" class="outof centered1 title center" fxLayout="row"
            fxLayoutAlign="center center" *ngIf="(qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0)">
            <small fxLayoutAlign="center center" class="colorRed">OUT OF STOCK</small>
          </div>
        </div>
      </span>
    </span>



  </div>
  <div class="productPrice padding-rl10" (click)="productShow(product)" fxLayout="row" fxLayoutAlign="start center">

    <div *ngIf="!displayNameFully" [style.height]="data.categoryPageProductNameHeight"
      [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}" fxFlex>
      <div fxLayout="row">
        <div class="truncate-text" [fxFlex]="prescriptionRequired?96:100">

          <calsoft-translator [translateValue]="name" [data]="data" [type]="'products'"></calsoft-translator>

        </div>
        <img fxFlex height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
          alt="prescriptionRequired">
      </div>

    </div>

    <div fxFlex *ngIf="displayNameFully" [style.height]="data.categoryPageProductNameHeight"
      [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}">
      <div fxLayout="row">
        <calsoft-translator [fxFlex]="prescriptionRequired?96:100" [translateValue]="name" [data]="data"
          [type]="'productsNew'"></calsoft-translator>
        <img height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
          alt="prescriptionRequired">
      </div>
    </div>

  </div>

  <div [ngClass]="{
      padinf:
        configurableProductObject['type'] == 'Configurable' &&
        configurableProductObject['configurableProductDropdown'] != null
    }" class="padding-rl10" *ngIf="averageRating >= 1" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <div class="review-Rating E_uFuv">
      {{ calculateAvgRating | number: "1.1-1" }}

      <span class="material-icons f11" style="font-size: 15px;"> star_rate </span>
    </div>
    <div>
      &#40;<span>{{ averageRating }}</span>&#41;
    </div>
  </div>
  <div [ngClass]="{ overlayProduct: qty <= 0 && manageStock == 1 }" *ngIf="averageRating == 0"
    style="padding-bottom: 20px"></div>

  <div *ngIf="showConfigurableDropdown == 1">

    <ng-container *ngIf="!showNewConfig; else newconfig">
      <div class="padding-rl10 A" fxLayout="row" *ngFor="let item of 
      configurationDropDownList | keyvalue">
        <mat-form-field style="
            font-size: 10px;
            height: 54px;
            padding: 0px;
            margin: 0px;
            padding-bottom: 0px;
          " appearance="outline" fxFlex="100">
          <mat-label style="font-size: 12px">
            {{ item.key.replace('_', ' ') }}
          </mat-label>
          <mat-select style="padding-bottom: 0.34375em; padding-top: 5px"
            (selectionChange)="getConfigProductId($event.value)" [disableOptionCentering]="true"
            [formControl]="dropDownValue">
            <mat-option style="font-size: 14px" [value]="object" *ngFor="let object of item.value">
              {{ object | splitbyHash }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-template #newconfig>

      <div class="padding-rl10 A" fxLayout="row">
        <mat-form-field style="
            font-size: 10px;
            height: 54px;
            padding: 0px;
            margin: 0px;
            padding-bottom: 0px;
          " appearance="outline" fxFlex="100">
          <mat-label style="font-size: 12px">
            {{configurationDropDownList[0].key}}
          </mat-label>
          <mat-select [(ngModel)]="configurationDropDownList[firstIndex].value" [disableOptionCentering]="true">
            <mat-option (onSelectionChange)="getConfigProductIdNew($event,i,item,'firstIndex')" [value]="item.value"
              *ngFor="let item of configurationDropDownList;let i=index;">
              {{item.value}}
            </mat-option>
          </mat-select>

        </mat-form-field>
      </div>

      <div class="padding-rl10 A" fxLayout="row">
        <mat-form-field style="
        font-size: 10px;
        height: 54px;
        padding: 0px;
        margin: 0px;
        padding-bottom: 0px;
      " appearance="outline" fxFlex="100">
          <mat-label style="font-size: 12px">
            {{configurationDropDownList[0]
            ['recursuive'][0].key}}

          </mat-label>
          <mat-select [(ngModel)]="configurationDropDownList[firstIndex]
              .recursuive[secondIndex].value" [disableOptionCentering]="true">
            <mat-option (onSelectionChange)="getConfigProductIdNew($event,j,item1,'secondIndex')" [value]="item1.value"
              *ngFor="let item1 of configurationDropDownList[firstIndex]
       ['recursuive'];let j=index;">
              {{item1.value}}
            </mat-option>
          </mat-select>


        </mat-form-field>
      </div>


    </ng-template>
  </div>

  <div fxLayout="row" style="padding-bottom: 10px;">
    <div fxFlex="100" style="padding-left: 12px;padding-bottom: 5px;">

      <div fxFlex="100" *ngIf="this.data.disablePrice==0">
        <div [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }">


          <div *ngIf="inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
            fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

            <span *ngIf="displayMrpPrice">
              <span *ngIf="specialPrice > 0 && specialPrice != price">
                <b>MRP :</b>
                <span class="old-price text-strike text-muted font-16px">
                  {{ price | currencyConvert }}{{units}}
                </span>
              </span>
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
              <span *ngIf="displayMrpPrice">
                <b>MRP :</b>
              </span>
              <b> {{ price | currencyConvert }}{{units}}</b> (incl. of tax)
            </span>

            <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
              <b> {{ specialPrice | currencyConvert }}{{units}}</b> (incl. of tax)
            </span>

            <span *ngIf="!displayMrpPrice">
              <span *ngIf="specialPrice > 0 && specialPrice != price"
                class="old-price text-strike text-muted font-16px">
                {{ price | currencyConvert }}{{units}}
              </span>
            </span>
          </div>

          <div *ngIf="!inclTaxEnabled" (click)="productShow(product)">
            <div>
              <div *ngIf="specialPrice > 0 && specialPrice != price">
                <b *ngIf="displayMrpPrice">MRP :</b>
                <p class="old-price text-strike text-muted font-16px">
                  {{ price | currencyConvert }}{{units}}
                </p>
              </div>

              <div class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
                <p *ngIf="displayMrpPrice">
                  <b>MRP :</b>
                </p>
                <b> {{ price | currencyConvert }}{{units}}</b>
              </div>

              <div class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
                <b> {{ specialPrice | currencyConvert }}{{units}}</b>
                <span *ngIf="displayMrpPrice">
                  (incl. of tax)
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>


  <div fxLayout="row" class="padding-10" fxLayoutAlign="space-between center" style="padding-bottom: 10px;">
    <div fxFlex="48">
      <div [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }">
        <calsoft-controls [product]="configProductSelected" [data]="data" [variant1Name]="variant1Name"
          [variant2Name]="variant2Name" [variant1Value]="variant1Value" [variant2Value]="variant2Value"
          [type]="'cartIcon'" [configproduct]="config " [showCartIcon]="showCartIcon"
          [showCartIncremented]="showCartIncremented">
        </calsoft-controls>
      </div>

      <div fxLayoutAlign="center center" *ngIf="(qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0)">

        <mat-icon [style.color]="data.carouselIconColor">remove_shopping_cart</mat-icon>

      </div>
    </div>

    <div fxFlex="45" fxFlex.lt-md="0">
      <button mat-button fxFlex="100" (click)="productShow(product)" class="preview-button">Preview</button>
    </div>
  </div>

</div>

<div *ngIf="productLayoutType==10" class="product-item product-elevat ext-center pointer">

  <div class="image">
    <div fxLayout="row" fxLayoutAlign="center center">
      <calsoft-image-lazy-loading *ngIf="showAnimation" [radius]="'12px'"
        [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }" class="padding-10"
        (click)="productShow(product)" [width]="width" [imageType]="imageType" [heightMobile]="heightMobile"
        [widthMobile]="widthMobile" [height]="height" [type]="type" class="pointer" [name]="name" [image]="image">
      </calsoft-image-lazy-loading>


      <calsoft-image-lazy-main-mouse-over-main *ngIf="!showAnimation" [type]="type"
        [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }" class="padding-10"
        (click)="productShow(product)" [qty]="qty" [product]="product" [width]="width" [imageType]="imageType"
        [heightMobile]="heightMobile" [widthMobile]="widthMobile" [height]="height" [type]="type" class="pointer"
        [name]="name" [list]="list"></calsoft-image-lazy-main-mouse-over-main>

    </div>
  </div>

  <div style="padding-bottom: 5px;">

  </div>

  <div class="productPrice padding-rl10" fxLayout="row" fxLayoutAlign="start center">

    <div *ngIf="!displayNameFully" [style.height]="data.categoryPageProductNameHeight"
      [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}" fxFlex>
      <div fxLayout="row">
        <div class="truncate-text" [fxFlex]="prescriptionRequired?96:100">

          <calsoft-translator [translateValue]="name" [data]="data" [type]="'products'"></calsoft-translator>

        </div>
        <img fxFlex height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
          alt="prescriptionRequired">
      </div>

    </div>

    <div fxFlex *ngIf="displayNameFully" [style.height]="data.categoryPageProductNameHeight"
      [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}">
      <div fxLayout="row">
        <calsoft-translator [fxFlex]="prescriptionRequired?96:100" [translateValue]="name" [data]="data"
          [type]="'productsNew'"></calsoft-translator>
        <img height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
          alt="prescriptionRequired">
      </div>
    </div>

  </div>

  <div class="productPrice space padding-rl10" (click)="openInNewWindow(MapLink)" fxLayout="row"
    fxLayoutAlign="start center">
    <div *ngIf="Address?.length>0" class="add_main">
      <span><i class="material-icons">location_on</i></span>
      <div class="address_main">
        {{Address}}
      </div>
    </div>
  </div>

  <div class="productPrice space padding-rl10" (click)="openInNewWindow(MapLink)" fxLayout="row"
    fxLayoutAlign="start center">
    <div fxLayout="row" fxLayoutGap="5px" *ngIf="Distance?.length>0" class="add_main">Distance:&#160;
      <div fxLayout="row">
        <span><mat-icon style="font-size:16px ;">flight</mat-icon></span>
        <div style="margin-left:-15px ;">
          {{ flightDistance }}
        </div>
      </div>
      <div fxLayout="row">
        <span><mat-icon style="font-size:16px ;">train</mat-icon></span>
        <div style="margin-left:-15px ;">
          {{ trainDistance }}
        </div>
      </div>
    </div>
  </div>

  <!-- <div  class=" productPrice padding-rl10" *ngIf="averageRating >= 1" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <calsoft-star-rate [averageRating]="averageRating" [calculateAvgRating]="calculateAvgRating">
    </calsoft-star-rate>
  </div>  -->


  <div [ngClass]="{
      padinf:
        configurableProductObject['type'] == 'Configurable' &&
        configurableProductObject['configurableProductDropdown'] != null
    }" class="padding-rl10" *ngIf="averageRating >= 1" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <calsoft-star-rate [averageRating]="averageRating" [calculateAvgRating]="calculateAvgRating">
    </calsoft-star-rate>
  </div>

  <div class="productPrice padding-rl10" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutGap="10px">
      <button (click)="dynamicFormPopup()" class="padding-10 addbtn primary" style="color:white" mat-button>Enquiry
      </button>

      <button (click)="productShow(product)" class="padding-10 addbtn primary" style="color:white" mat-button>Explore
      </button>


    </div>

    <div *ngIf="data?.customButtonInCategroyPage; else bookNowSection">
      <div fxLayout="row" class="padding-10" fxLayoutAlign="center center">
        <button fxFlex="100" (click)="PageNavigation(data.customButtonInCategroyPageNavigation)" class="primary"
          style="color: white;" mat-button>
          {{ data?.customButtonInCategroyPage }}
        </button>
      </div>
    </div>

    <ng-template #bookNowSection>
      <div class="padding-lr10"
        [ngClass]="{ overlayProduct: (qty <= 0 || isInStock == 0) && (manageStock == 1 || isInStock == 0) }">
        <calsoft-controls [product]="configProductSelected" [data]="data" [variant1Name]="variant1Name"
          [variant2Name]="variant2Name" [variant1Value]="variant1Value" [variant2Value]="variant2Value"
          [configproduct]="config" [type]="'booknow'" [showCartIcon]="showCartIcon"
          [showCartIncremented]="showCartIncremented">
        </calsoft-controls>
      </div>
    </ng-template>


  </div>

  <div style="padding-bottom: 5px;">

  </div>


</div>

<div class="pointer" *ngIf="productLayoutType==11" class="product-item product-elevat ext-center">
  <div fxLayout="row" fxLayoutAlign="space-between center" style="position: sticky;">

    <div class="sale-label" *ngIf="discountPercentage > 0 && discountPercentage < 100">
      {{ discountPercentage | dynamicRound}}% Off
    </div>
  </div>

  <div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <img onerror="this.src='assets/images/products/placeholder.jpg';" [src]="image" [offset]="100" [alt]="name"
        [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }" class="padding-10"
        (click)="productShow(product)"
        [ngStyle.lt-md]="{visibility:isLoading?'hidden':'',height:height==0?'auto':heightMobile+'px',width:width==0?'auto':widthMobile+'px'}"
        [ngStyle.gt-sm]="{visibility:isLoading?'hidden':'',height:height==0?'auto':height+'px',width:width==0?'auto':width+'px'}"
        [name]="name" [image]="image" class="pointer" [name]="name">
    </div>

    <span *ngIf="product['type']!='Course'">

      <span *ngIf="this.product.type!='Booking'">
        <div *ngIf="outofstockEnabled==1">
          <div (click)="productShow(product)" class="outof centered1 title center" fxLayout="row"
            fxLayoutAlign="center center" *ngIf="(qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0)">
            <small fxLayoutAlign="center center" class="colorRed">OUT OF STOCK</small>
          </div>
        </div>
      </span>
    </span>
  </div>

  <div class="padding-5">

  </div>

  <div class="productPrice padding-rl10" (click)="productShow(product)" fxLayout="row" fxLayoutAlign="start center">

    <div *ngIf="!displayNameFully" [style.height]="data.categoryPageProductNameHeight"
      [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}" fxFlex>
      <div fxLayout="row">
        <div class="truncate-text" [fxFlex]="prescriptionRequired?96:100">

          <calsoft-translator [translateValue]="name" [data]="data" [type]="'products'"></calsoft-translator>

        </div>
        <img fxFlex height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
          alt="prescriptionRequired">
      </div>

    </div>

    <div fxFlex *ngIf="displayNameFully" [style.height]="data.categoryPageProductNameHeight"
      [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}">
      <div fxLayout="row">
        <calsoft-translator [fxFlex]="prescriptionRequired?96:100" [translateValue]="name" [data]="data"
          [type]="'productsNew'"></calsoft-translator>
        <img height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
          alt="prescriptionRequired">
      </div>
    </div>

  </div>

  <div class="padding-5">

  </div>

  <div *ngIf="this.data.disablePrice==0">
    <div [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }">

      <div *ngIf="inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
        fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

        <span *ngIf="displayMrpPrice">
          <span *ngIf="specialPrice > 0 && specialPrice != price">
            <b>MRP :</b>
            <span class="old-price text-strike text-muted font-16px">
              {{ price | currencyConvert }}{{units}}
            </span>
          </span>
        </span>

        <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
          <span *ngIf="displayMrpPrice">
            <b>MRP :</b>
          </span>
          <b> {{ price | currencyConvert }}{{units}}</b> (incl. of tax)
        </span>

        <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
          <b> {{ specialPrice | currencyConvert }}{{units}}</b> (incl. of tax)
        </span>

        <span *ngIf="!displayMrpPrice">
          <span *ngIf="specialPrice > 0 && specialPrice != price" class="old-price text-strike text-muted font-16px">
            {{ price | currencyConvert }}{{units}}
          </span>
        </span>



      </div>

      <div *ngIf="!inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
        fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

        <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
          <b>Price :</b> <b> {{ price | currencyConvert }}{{units}}</b>
        </span>

        <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
          <b>Price :</b> <b> {{ specialPrice | currencyConvert }}{{units}}</b>
        </span>
      </div>

    </div>
  </div>

  <div *ngIf="BRANDS?.length>0" class="productPrice space padding-rl10" (click)="productShow(product)" fxLayout="row"
    fxLayoutAlign="start center">
    <div>
      <b>Brand : </b> <b>{{BRANDS}}</b>
    </div>
  </div>

  <!-- <div class="productPrice space padding-rl10" (click)="productShow(product)" fxLayout="row"
    fxLayoutAlign="start center">
    <div>
      <b>Weight : </b> <b>{{weight}}</b>
    </div>
  </div> -->

  <div fxLayout="row" class="padding-10" fxLayoutAlign="center center">
    <button fxFlex="100" (click)="productShow(product)" class="primary" style="color: white;" mat-button>VIEW CATALOG
    </button>
  </div>
</div>

<div class="pointer" *ngIf="productLayoutType==12" class="product-item product-elevat ext-center">

  <div>
    <div fxLayout="row" fxLayoutAlign="center center">

      <img onerror="this.src='assets/images/products/placeholder.jpg';" [src]="image" [offset]="100" [alt]="name"
        [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }" class="padding-10"
        (click)="productShow(product)"
        [ngStyle.lt-md]="{visibility:isLoading?'hidden':'',height:height==0?'auto':heightMobile+'px',width:width==0?'auto':widthMobile+'px'}"
        [ngStyle.gt-sm]="{visibility:isLoading?'hidden':'',height:height==0?'auto':height+'px',width:width==0?'auto':width+'px'}"
        [name]="name" [image]="image" class="pointer" [name]="name">
    </div>
  </div>

  <div class="padding-5">

  </div>

  <div class="productPrice padding-rl10" (click)="productShow(product)" fxLayout="row" fxLayoutAlign="start center">

    <div *ngIf="!displayNameFully" [style.height]="data.categoryPageProductNameHeight"
      [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}" fxFlex>
      <div fxLayout="row">
        <div class="truncate-text" [fxFlex]="prescriptionRequired?96:100">

          <calsoft-translator [translateValue]="name" [data]="data" [type]="'products'"></calsoft-translator>

        </div>
        <img fxFlex height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
          alt="prescriptionRequired">
      </div>

    </div>

    <div fxFlex *ngIf="displayNameFully" [style.height]="data.categoryPageProductNameHeight"
      [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}">
      <div fxLayout="row">
        <calsoft-translator [fxFlex]="prescriptionRequired?96:100" [translateValue]="name" [data]="data"
          [type]="'productsNew'"></calsoft-translator>
        <img height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
          alt="prescriptionRequired">
      </div>
    </div>

  </div>

  <div style="padding-top: 10px;" class="padding-rl10" [innerHTML]="description1">

  </div>

  <div fxLayout="row" class="padding-10" fxLayoutAlign="center center">
    <button fxFlex="100" (click)="productShow(product)" class="primary" style="color: white;" mat-button>{{
      data?.customButtonInCategroyPage || 'View Catalog' }}
    </button>
  </div>
</div>

<div *ngIf="productLayoutType==13" style="border-radius: 30px;" class="product-item product-elevat1 ext-center pointer">

  <div class="padding-10">

    <div class="image">
      <div style="padding-top: 10px;padding: 10px;" fxLayout="row" fxLayoutAlign="center center">
  
        <img style="border-radius: 15px;" onerror="this.src='assets/images/products/placeholder.jpg';" [src]="image"
          [offset]="100" [alt]="name"
          [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }" class="padding-10"
          (click)="productShow(product)"
          [ngStyle.lt-md]="{visibility:isLoading?'hidden':'',height:height==0?'auto':heightMobile+'px',width:width==0?'auto':widthMobile+'px'}"
          [ngStyle.gt-sm]="{visibility:isLoading?'hidden':'',height:height==0?'auto':height+'px',width:width==0?'auto':width+'px'}"
          [name]="name" [image]="image" class="pointer" [name]="name">
      </div>
    </div>
  
    <div style="padding-bottom: 5px;">
  
    </div>
  
    <div class="productPrice padding-rl10" fxLayout="row" fxLayoutAlign="start center">
  
      <div *ngIf="!displayNameFully" [style.height]="data.categoryPageProductNameHeight"
        [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}" style="margin-bottom: 15px;" fxFlex>
        <div fxLayout="row">
          <div class="truncate-text" [fxFlex]="prescriptionRequired?96:100">
  
            <calsoft-translator [translateValue]="name" [data]="data" [type]="'products'"></calsoft-translator>
  
          </div>
        </div>
  
      </div>
  
      <div fxFlex *ngIf="displayNameFully" [style.height]="data.categoryPageProductNameHeight"
        [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}">
        <div fxLayout="row">
          <calsoft-translator [fxFlex]="prescriptionRequired?96:100" [translateValue]="name" [data]="data"
            [type]="'productsNew'"></calsoft-translator>
        </div>
      </div>
  
    </div>
  
    <div class="productPrice padding-rl10" fxLayout="row" fxLayoutAlign="start center">
      <div *ngIf="propertyType?.length>0" class="add_main">
        <span><i class="material-icons">home</i></span>
        <div class="address_main">
          {{propertyType}}
        </div>
      </div>
    </div>
  
    <div class="productPrice padding-rl10" (click)="openInNewWindow(MapLink)" fxLayout="row"
      fxLayoutAlign="start center">
      <div  class="add_main">
        <span><i class="material-icons">location_on</i></span>
        <div *ngIf="Address?.length>0" class="address_main">
          {{Address}}
        </div>
      </div>
    </div>
  
    <div class="productPrice padding-rl10"  fxLayout="row"
      fxLayoutAlign="start center">
      <div class="add_main">
        <span><i class="material-icons">money</i></span>
        <div class="address_main">
          {{ price | currencyConvert }}{{units}}
        </div>
      </div>
    </div>
  
    <div class="productPrice  padding-rl10"  fxLayout="row"
      fxLayoutAlign="start center" style="margin-bottom: 15px;">
      <div *ngIf="rooms?.length>0" class="add_main">
        <span><i class="material-icons">hotel</i></span>
        <div class="address_main">
          {{rooms}}
        </div>
      </div>
    </div>
  
  
    <div class="productPrice padding-rl10" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
      <div fxFlex="100"  fxLayout="row" fxLayoutGap="10px">
  
        <button fxFlex="100" (click)="productShow(product)" mat-stroked-button>
          More Details
        </button>
  
  
      </div>
    </div>
  
    <div style="padding-bottom: 5px;">
      
    </div>

  </div>

 


</div>

<div class="pointer" *ngIf="productLayoutType==14" class="product-item product-elevat ext-center">
  <div fxLayout="row" fxLayoutAlign="space-between center" style="position: sticky;z-index: 10;">
    <calsoft-controls [data]="data" [variant1Name]="variant1Name" [variant2Name]="variant2Name"
      [variant1Value]="variant1Value" [variant2Value]="variant2Value" [product]="configProductSelected"
      [configproduct]="config " style="position: absolute; top: 0; left: 0" [type]="'wishonly'">
    </calsoft-controls>

    <div class="sale-label" style="z-index: 1000;" *ngIf="discountPercentage > 0 && discountPercentage < 100">

      {{ discountPercentage | dynamicRound}}% Off
    </div>
  </div>

  <div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <calsoft-image-lazy-loading *ngIf="showAnimation"
        [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }" class="padding-10"
        (click)="productShow(product)" [width]="width" [imageType]="imageType" [heightMobile]="heightMobile"
        [widthMobile]="widthMobile" [height]="height" [type]="type" class="pointer" [name]="name" [image]="image">
      </calsoft-image-lazy-loading>


      <calsoft-image-lazy-main-mouse-over-main *ngIf="!showAnimation" [type]="type"
        [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }" class="padding-10"
        (click)="productShow(product)" [qty]="qty" [product]="product" [width]="width" [imageType]="imageType"
        [heightMobile]="heightMobile" [widthMobile]="widthMobile" [height]="height" [type]="type" class="pointer"
        [name]="name" [list]="list"></calsoft-image-lazy-main-mouse-over-main>

    </div>
    <span *ngIf="product['type']!='Course'">
      <span *ngIf="this.product.type!='Booking'">
        <div *ngIf="outofstockEnabled==1">
          <div (click)="productShow(product)" class="outof centered1 title center" fxLayout="row"
            fxLayoutAlign="center center" *ngIf="(qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0)">
            <small fxLayoutAlign="center center" class="colorRed">OUT OF STOCK</small>
          </div>
        </div>
      </span>
    </span>



  </div>
  <div style="padding-top: 10px;" class="productPrice padding-rl10" (click)="productShow(product)" fxLayout="row" fxLayoutAlign="start center">

    <div *ngIf="!displayNameFully" [style.height]="data.categoryPageProductNameHeight"
      [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}" fxFlex>
      <div fxLayout="row">
        <div class="truncate-text" [fxFlex]="prescriptionRequired?96:100">

          <calsoft-translator [translateValue]="name" [data]="data" [type]="'products'"></calsoft-translator>

        </div>
        <img fxFlex height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
          alt="prescriptionRequired">
      </div>

    </div>

    <div fxFlex *ngIf="displayNameFully" [style.height]="data.categoryPageProductNameHeight"
      [ngStyle.lt-md]="{ 'height': categoryPageProductNameMobHeight}">
      <div fxLayout="row">
        <calsoft-translator [fxFlex]="prescriptionRequired?96:100" [translateValue]="name" [data]="data"
          [type]="'productsNew'"></calsoft-translator>
        <img height="27px" src="assets/images/rx.svg" style="margin-bottom:0px" *ngIf="prescriptionRequired"
          alt="prescriptionRequired">
      </div>
    </div>



  </div>


  <div [ngClass]="{ overlayProduct: qty <= 0 && manageStock == 1 }" *ngIf="averageRating == 0"
    style="padding-bottom: 20px"></div>

  <div *ngIf="showConfigurableDropdown == 1">

    <ng-container *ngIf="!showNewConfig; else newconfig">
      <div class="padding-rl10 A" fxLayout="row" *ngFor="let item of 
      configurationDropDownList | keyvalue">
        <mat-form-field style="
            font-size: 10px;
            height: 54px;
            padding: 0px;
            margin: 0px;
            padding-bottom: 0px;
          " appearance="outline" fxFlex="100">
          <mat-label style="font-size: 12px">
            {{ item.key.replace('_', ' ') }}
          </mat-label>
          <mat-select style="padding-bottom: 0.34375em; padding-top: 5px"
            (selectionChange)="getConfigProductId($event.value)" [disableOptionCentering]="true"
            [formControl]="dropDownValue">
            <mat-option style="font-size: 14px" [value]="object" *ngFor="let object of item.value">
              {{ object | splitbyHash }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-template #newconfig>

      <div class="padding-rl10 A" fxLayout="row">
        <mat-form-field style="
            font-size: 10px;
            height: 54px;
            padding: 0px;
            margin: 0px;
            padding-bottom: 0px;
          " appearance="outline" fxFlex="100">
          <mat-label style="font-size: 12px">
            {{configurationDropDownList[0].key}}
          </mat-label>
          <mat-select [(ngModel)]="configurationDropDownList[firstIndex].value" [disableOptionCentering]="true">
            <mat-option (onSelectionChange)="getConfigProductIdNew($event,i,item,'firstIndex')" [value]="item.value"
              *ngFor="let item of configurationDropDownList;let i=index;">
              {{item.value}}
            </mat-option>
          </mat-select>

        </mat-form-field>
      </div>

      <div class="padding-rl10 A" fxLayout="row">
        <mat-form-field style="
        font-size: 10px;
        height: 54px;
        padding: 0px;
        margin: 0px;
        padding-bottom: 0px;
      " appearance="outline" fxFlex="100">
          <mat-label style="font-size: 12px">
            {{configurationDropDownList[0]
            ['recursuive'][0].key}}

          </mat-label>
          <mat-select [(ngModel)]="configurationDropDownList[firstIndex]
              .recursuive[secondIndex].value" [disableOptionCentering]="true">
            <mat-option (onSelectionChange)="getConfigProductIdNew($event,j,item1,'secondIndex')" [value]="item1.value"
              *ngFor="let item1 of configurationDropDownList[firstIndex]
       ['recursuive'];let j=index;">
              {{item1.value}}
            </mat-option>
          </mat-select>


        </mat-form-field>
      </div>


    </ng-template>
  </div>

  <div style="padding-top: 10px;" *ngIf="this.data.disablePrice==0">
    <div [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }">
      <div *ngIf="inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
        fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

        <span *ngIf="displayMrpPrice">
          <span *ngIf="specialPrice > 0 && specialPrice != price">
            <b>MRP :</b>
            <span class="old-price text-strike text-muted font-16px">
              {{ price | currencyConvert }}{{units}}
            </span>
          </span>
        </span>

        <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
          <span *ngIf="displayMrpPrice">
            <b>MRP :</b>
          </span>
          <b> {{ price | currencyConvert }}{{units}}</b> (incl. of tax)
        </span>

        <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
          <b> {{ specialPrice | currencyConvert }}{{units}}</b> (incl. of tax)
        </span>

        <span *ngIf="!displayMrpPrice">
          <span *ngIf="specialPrice > 0 && specialPrice != price" class="old-price text-strike text-muted font-16px">
            {{ price | currencyConvert }}{{units}}
          </span>
        </span>



      </div>

      <div *ngIf="!inclTaxEnabled" class="padding-rl10" (click)="productShow(product)" class="pointer padding-rl10"
        fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="{{ productPriceCenter ? 'center' : 'start' }}">

        <span *ngIf="specialPrice > 0 && specialPrice != price">
          <b *ngIf="displayMrpPrice">MRP :</b>
          <span class="old-price text-strike text-muted font-16px">
            {{ price | currencyConvert }}{{units}}
          </span>
        </span>

        <span class="new-price123 price-label font-16px" *ngIf="specialPrice <= 0">
          <span *ngIf="displayMrpPrice">
            <b>MRP :</b>
          </span>
          <b> {{ price | currencyConvert }}{{units}}</b>
        </span>

        <span class="new-price123 price-label font-16px" *ngIf="specialPrice > 0">
          <b> {{ specialPrice | currencyConvert }}{{units}}</b>
          <span *ngIf="displayMrpPrice">
            (incl. of tax)
          </span>
        </span>
      </div>
    </div>
  </div>

  <div class="padding-lr10"
    [ngClass]="{ overlayProduct: (qty <= 0||isInStock==0) && (manageStock == 1||isInStock==0) }">
    <calsoft-controls [product]="configProductSelected" [data]="data" [variant1Name]="variant1Name"
      [variant2Name]="variant2Name" [variant1Value]="variant1Value" [variant2Value]="variant2Value"
      [configproduct]="config" [type]="'coursecart'" [showCartIcon]="showCartIcon" [showCartIncremented]="showCartIncremented">
    </calsoft-controls>


  </div>
</div>